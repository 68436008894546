import React, { useState, useEffect } from 'react';
import './DataGenerator.css';
import Checkbox from './DataGeneratorComponent/Checkbox';
import { hostLink } from '../../DatabaseCommunication/GetDataFromUser';
import Cookies from "js-cookie";
import ChannelInsights from './YoutubeGenerator';
import {GetCompanyId, GetUserDatas} from "../../GatherUserInformationDB/GatherDatabaseUser.js";
import LoadingModule from '../../loading/loading.js';
import HowItWorks from './HowItWorks/HowItWorks.js';



const DataGenerator = () => 
    {           
        const [makeIsGenerating, setGenerating] = useState(false);
        const [isModalOpen, SetModalOpen] = useState(false);
   
        async function GenerateData()
        {
        const userData = await GetUserDatas();

        const tokens = await fetch(hostLink + "/get_token/" + userData[4]);
        const tokensContent = await tokens.json();
        setGenerating(true);

        if(!tokensContent["access_token"])
        {
            window.location.href = hostLink + '/authorize?state=' + (JSON.stringify({ email: userData[4] }));
        }
        const pythonOutput = document.getElementById('python-output');
        const idcompJson = await GetCompanyId();
        const jsonizeId = await idcompJson.json();
        console.log(jsonizeId);
        let dataToSave = {};
        // Retrieve the JSON string from cookies and parse it back into an object
        if (Cookies.get("userNetworkData"))
        {
            dataToSave = JSON.parse(Cookies.get("userNetworkData"));
        }

        dataToSave["instaToken"]= Cookies.get("accessTokenInstagram");
        dataToSave["instaId"]= Cookies.get("userIdInstagram");
            
        console.log(dataToSave);
        fetch(hostLink + '/GenerateDataNetwork', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                networksIdentifiant: dataToSave,
                idcomp: jsonizeId.id,
                access_token: tokensContent["access_token"],
                refresh_token: tokensContent['refresh_token'],
                token_uri: tokensContent['token_uri'],
                client_id: tokensContent['client_id'],
                client_secret: tokensContent['client_secret']
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log("Response from server:", data);
            pythonOutput.innerHTML = '';
            setGenerating(false);   
            // Loop through each network in message
            for (const [networkName, networkInfo] of Object.entries(data.message))
            {
                const newDiv = document.createElement("div");
                const status = networkInfo['status']; 
                newDiv.textContent = `${networkName} : ${status == 200 ? 'Ok !' : 'error code ' + status}`;
                newDiv.style.color = status == 200 ? 'green' : 'red';
                newDiv.style.fontWeight = 'bold';
                pythonOutput.appendChild(newDiv);
            }
        })

        }
    
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            console.log(value);
        
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        function ShowHowItWorks()
        {
            SetModalOpen(true);
        }

        return (
            <div className="data-generator-container">
                {makeIsGenerating && (
                    <div className="data-generator-overlay">
                        <LoadingModule text="Génération des données..." />
                    </div>
                )}
        
                <div className="data-generator-header">
                    <h1>Un problème avec les données ?</h1>
                    <p className="data-generator-subtext">
                        Nous sommes là pour vous aider à régénérer vos informations.
                    </p>
                    <hr />
                </div>
        
                <div className="data-generator-content">
                    <div className="data-generator-card">
                        <h2>Pas de données pour le dernier mois ?</h2>
                        <p>
                            Aucun problème ! Cliquez sur le bouton ci-dessous pour récupérer vos données
                            rapidement et en toute sécurité.
                        </p>
                        <button
                            type="button"
                            className="data-generator-button"
                            onClick={GenerateData}
                        >
                            Générer les données
                        </button>
                        <p id="python-output" className="data-generator-output">
                            Les informations sur la génération apparaîtront ici une fois terminées.
                        </p>
                    </div>
                </div>
        
                <div className="data-generator-footer">
                    <div
                        className="helper-link"
                        onClick={() => ShowHowItWorks()}
                    >
                        Comment ça fonctionne ?
                    </div>
                    <HowItWorks open={isModalOpen} reset={SetModalOpen} />
                </div>
            </div>
        );
        
        
        
    };
    

    export default DataGenerator;