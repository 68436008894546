import React, { useState, useEffect } from 'react';
import SideMenu from '../../SideBar/sideBar';
import ExpandableModule from './ExpandableModule';
import './WeeklyAnalysis.css';

const AnalyseHebdomadaire = () => {
    const [currentDate, setCurrentDate] = useState('');
    const [networkTrendData, setNetworkTrendData] = useState([]);

    useEffect(() => {
        const date = new Date("2024-11-15");
        setCurrentDate(date.toLocaleDateString()); // Vous pouvez personnaliser le format de la date si nécessaire
        
        // Récupérer les données de tendance du réseau
        async function fetchNetworkTrendData() {
            try {
                const response = await fetch("http://127.0.0.1:5000/GetGithubFile", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        filepath: "network/WeeklyAnalysis.json", 
                        repo: "PulseBackend", 
                        branch: "main"
                    })
                });
        
                const result = await response.json();
                console.log("Réponse de l'API:", result); // Afficher la réponse complète
                const jsonized = result["content"];
                console.log(jsonized);        

                setNetworkTrendData(jsonized);
            } catch (error) {
                console.error("Erreur lors de la récupération des données:", error);
            }
        }

        fetchNetworkTrendData(); // Appeler la fonction de récupération des données
    }, []); // Le tableau de dépendances vide garantit que cela s'exécute une seule fois après le montage du composant

    return (
        <div className="back">
            <SideMenu />
            <div className="containerWeekly">
                <h1 className="title">Analyse hebdomadaire des réseaux sociaux</h1>
                <p className="date">{currentDate}</p>
                
                <div className="contentBox">
                    <p className="description">
                        Ce rapport fournit des informations sur la performance des réseaux au cours de la semaine écoulée, y compris les principaux indicateurs, tendances et domaines à améliorer. 
                        Une analyse détaillée sera fournie sur les différents paramètres des réseaux qui affectent la performance globale.
                    </p>
                    <div className="verticalBar"></div>
                    <div style={{flex: "1", justifyItems: "center", display:"flex", justifyContent: "center"}}>
                        <img className="image" src="https://img.freepik.com/free-vector/site-stats-concept-illustration_114360-1434.jpg?t=st=1731864727~exp=1731868327~hmac=7282698d02cfbe1b9d820f82221cb63613e62ce7e558ab94d77291c4503d8fc4&w=826" alt="Analyse du Réseau" />
                    </div>
                </div>
                <div>
                    {/* Vérifier si networkTrendData est un tableau avant de le rendre */}
                    {Array.isArray(networkTrendData) && networkTrendData.length > 0 ? (
                        networkTrendData.map((trend, index) => (
                            <ExpandableModule 
                                key={index}
                                title={trend.title} 
                                content={trend.content} 
                                othersExp={trend.othersExp} 
                            />
                        ))
                    ) : (
                        <p>Aucune donnée disponible</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AnalyseHebdomadaire;
