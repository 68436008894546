import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./tableStyle.css";
import { hostLink } from "../../../DatabaseCommunication/GetDataFromUser";
import { GetCompanyId } from "../../../GatherUserInformationDB/GatherDatabaseUser";
import LoadingModule from "../../../loading/loading";
import { Marker } from "ag-charts-community";

const CreateTable = ({ col, row, allDatas, network = "insta" }) => 
{
    const [rowData, setRowData] = useState([]);
    const [predictionData, setPredictionData] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false); // State for modal visibility
    const [isPredictionOnly, setPredictionOnly] = useState(false); // State for prediction choice
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => 
        {
            const fetchData = async () => 
                {
                    try 
                    {
                        // Fetch company ID
                        let compId = await GetCompanyId();
                        compId = await compId.json();
                        compId = compId["id"];
                
                        // Fetch prediction data
                        let pred = await fetch(hostLink + "/GetPredictionData", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ id: compId }),
                        });
                
                        pred = await pred.json(); // Parse the prediction response
                        pred = pred["data"].map(JSON.parse); // Parse each element as JSON
                        pred = pred[0];
                
                        // Update existing row data
                        const updatedRowData = row.map((dataRow) => 
                        {
                            const rowWithPrediction = { ...dataRow };
                
                            col.forEach((column) => 
                            {
                                const predictionField = `${column.field}_prediction`;
                
                                if (pred[dataRow["date"]]) 
                                {
                                    rowWithPrediction[predictionField] = pred[dataRow["date"]][predictionField] ?? 0;
                                } 
                                else 
                                {
                                    rowWithPrediction[predictionField] = 0;
                                }
                            });
                
                            return rowWithPrediction;
                        });
                
                        // Add remaining rows from pred
                        const rowDates = new Set(row.map((dataRow) => dataRow["date"]));
                
                        Object.keys(pred).forEach((date) => 
                        {
                            if (!rowDates.has(date)) 
                            {
                                const newRow = { date };
                
                                col.forEach((column) => 
                                {
                                    const predictionField = `${column.field}_prediction`;
                
                                    // Set predictions or default to 0
                                    newRow[predictionField] = pred[date][predictionField] ?? 0;
                                    newRow[column.field] = 0; // Set non-prediction fields to 0
                                });
                
                                updatedRowData.push(newRow);
                            }
                        });
                
                        console.log(updatedRowData);
                
                        // Combine updated rows with extra predictions
                        setRowData([...updatedRowData]);
                    } 
                    catch (error) 
                    {
                        console.error("Error fetching prediction data:", error);
                    }
                };
                
        
            fetchData(); // Call the async function inside useEffect
        }, [row, col]);
        
        

    const extendedColumnDefs = ExtendColumnsWithPrediction(col);

    const handleCellValueChanged = (event) => 
    {
        const updatedData = [...rowData];
        const rowIndex = event.node.rowIndex;

        updatedData[rowIndex][event.colDef.field] = event.newValue;
        setRowData(updatedData);
    };

    const AddNewRow = () => 
        {
            const lastRow = rowData[rowData.length - 1];
            
            const newRow = lastRow 
                ? Object.fromEntries(Object.keys(lastRow).map(key => [key, null])) 
                : {};
            
            if (lastRow && lastRow["date"]) 
            {
                // Convertir la date en objet Date
                const lastDate = new Date(lastRow["date"]);
                
                // Ajouter un mois
                lastDate.setMonth(lastDate.getMonth() + 1);
                
                // Mettre à jour le champ "date" en format "YYYY-MM"
                newRow["date"] = `${lastDate.getFullYear()}-${String(lastDate.getMonth() + 1).padStart(2, "0")}`;
            } 
            else 
            {
                // Initialisation d'une date si aucune n'existe
                const currentDate = new Date();
                newRow["date"] = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, "0")}`;
            }
            
            setRowData([...rowData, newRow]);
        };
        
        
        const parseRowData = (rowData) => 
            {
                // Map through each dictionary in the list
                return rowData.map((dataRow) => 
                {
                    // Filter the keys to only include ones ending with '_prediction'
                    const filteredDataRow = Object.keys(dataRow)
                        .filter((key) => key.endsWith('_prediction')) // Only keep keys with "_prediction"
                        .reduce((obj, key) => 
                        {
                            obj[key] = dataRow[key]; // Retain the key-value pair
                            return obj;
                        }, {});
            
                    return filteredDataRow;
                }).filter((filteredDataRow) => Object.keys(filteredDataRow).length > 0); // Remove empty dictionaries
            };
    const saveData = async() =>
        {
            setIsSaving(true);
            let compId = await GetCompanyId();
            compId = await compId.json();
            compId = compId["id"];
            const fetchResult = await fetch(hostLink + "/UpdateCompanyNetworkDatabase", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ allNewRow: rowData, id : compId, network: network }),
            })
            setIsSaving(false);
        }


    return (
        <>
        {isSaving && <LoadingModule/>}
            <div className="ag-theme-custom" style={{ height: 500 }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={extendedColumnDefs}
                    onCellValueChanged={handleCellValueChanged}
                    defaultColDef={{
                        editable: false,
                        resizable: true,
                    }}
                />
            </div>
            <div className="tableSwitcher">
                <button id="addEmptyRow" onClick={() => AddNewRow()}>Ajouter une ligne vide</button>
                <button id="saveData" onClick={() => saveData()}>Sauvegarder le tableau</button>
            </div>
        </>
    );

    function ExtendColumnsWithPrediction(columns) 
    {
        return columns.flatMap((column) => 
        {
            if (column.field !== "id" && column.field !== "date") 
            {
                return [
                    {
                        headerName: "",
                        children: [
                            { ...column },
                            {
                                field: `${column.field}_prediction`,
                                headerName: `Prediction`,
                                cellStyle: (params) => comparePrediction(params),
                                editable: true,
                            },
                        ],
                    },
                ];
            } 
            else 
            {
                return column;
            }
        });
    }

    function comparePrediction(params) 
    {
        const actualValue = params.data[params.colDef.field.replace("_prediction", "")];
        const predictedValue = params.value;

        if (predictedValue < actualValue) 
        {
            return { backgroundColor: "#c8e6c9", color: "#2e7d32" };
        } 
        else if (predictedValue === actualValue) 
        {
            return { backgroundColor: "#bbdefb", color: "#0d47a1" };
        } 
        else 
        {
            return { backgroundColor: "#ffcdd2", color: "#c62828" };
        }
    }
};

export default CreateTable;
