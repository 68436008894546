import React, { useState, useEffect } from 'react';
import './Network.css';
import DataGenerator from './DataGenerator';
import SideMenu from '../SideBar/sideBar';
import Team from '../../Sidebar/Team.js';
import Notifications from '../../Sidebar/Notification.js';
import CircularGraphHours from './CircularGraphHours/CircularGraphHours.js';
import OvertimeAnalyseTable from './Table/OvertimeAnalyseTable.js';
import OvertimeAnalyseTableYoutube from './Table/YoutubeTable/OvertimeAnalyseTableYoutube.js';
import PdfCreator from './PdfCreator.js';
import { GetUserCompanyData } from '../../GatherUserInformationDB/GatherDatabaseUser.js';
import ChartToPDF from './tableTest.js';
import { GetActualUserId, hostLink } from '../../DatabaseCommunication/GetDataFromUser.js';
import { Link, useNavigate } from 'react-router-dom';
import LoadingModule from '../../loading/loading.js';
import WeeklyAnalysis from './WeeklyAnalysis/WeeklyAnalysis.js';
import NetworkChooser from './NetworkChooser/NetworkChooser.js';

export const Networks = {
    INSTAGRAM: 'instagram',
    YOUTUBE: 'youtube',
    TIKTOK: 'tiktok'
};


const NetworkPage = () =>
{
    const navigate = useNavigate(); // Hook for navigation

    const [choosedNetwork, SetChoosedNetwork] = useState(Networks.INSTAGRAM);
    const [isLoading, SetIsLoading] = useState(true);
    const [sampleData, SetSampleData] = useState([]);
    const [companyData, SetcompanyData] = useState([]);
    const generalData = {
        compName : "Your company"
    };

    const DataForPdfGenerator = async () => 
        {
            let finalDatas = [];
            const compData = await GetUserCompanyData();
            SetcompanyData(compData);
            const keys = Object.keys(compData[0]).sort((a, b) => new Date(b) - new Date(a)).slice(0, 2);
            const currentData = compData[0][keys[0]];
            const previousData = compData[0][keys[1]];
            console.log(currentData);
            console.log(previousData);
            Object.keys(currentData).forEach(metricKey => {
                const [network, ...metricParts] = metricKey.split("_");
                const metricName = metricParts.join("_");
            
                const currentStat = currentData[metricKey] || 0;
                const previousStat = previousData && previousData[metricKey] ? previousData[metricKey] : 0;
                console.log(metricName);
                addStat(network, metricName, currentStat, previousStat);
            });

            // Fonction d'ajout
            function addStat(network, name, currentStat, previousStat) {
                finalDatas.push({
                    network: network,
                    name: name,
                    currentStat: currentStat,
                    previousStat: previousStat
                });
            }

        
            // Update the state
            SetSampleData(finalDatas);
        };
        
    
    const ChangeStatsNetworkType = (network = 'instagram') => 
        {
            console.log(`Displaying stats for ${network}`);
            SetChoosedNetwork(network);
        };

        useEffect(() => {
            DataForPdfGenerator();
        }, [])


        


    const isUserAvailable = async () =>
    {
        const userId = await GetActualUserId();

        const hasaccess = await fetch(hostLink + "/GetUserIsSubEchowave", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({customer_id : userId}),
        });
        const finalResult = await hasaccess.json();
        console.log(finalResult);
        if (finalResult.hasaccess == false)
        {
            navigate("/Account");
        }
    }

    useEffect(() => {
        isUserAvailable();
    }, [])


    return (
        <div className="containerAllNetworks">
            <div className="access-bar-content">
            <NetworkChooser ChangeStatsNetworkType={ChangeStatsNetworkType}/>
            <PdfCreator data={sampleData} generalData={generalData} allDatas={companyData[0]}/>
                <Notifications />
                <Team />
                <Link to={"/echowave/Network/weekAnalysis"}>
                    <button className='button'>Weekly analysis</button>
                </Link>
            </div>
            <SideMenu/>
            <DataGenerator/>
            

            {choosedNetwork == Networks.INSTAGRAM && (
                <><CircularGraphHours/>
                <OvertimeAnalyseTable/></>
            )}
            { choosedNetwork == Networks.YOUTUBE && (
                <OvertimeAnalyseTableYoutube/>
            )}
            
        </div>
    );
};

export default NetworkPage;
