import { hostLink } from "../DatabaseCommunication/GetDataFromUser";
import { loadMessages} from "./showChatPopup";

var block = false;


function SendMessage({selectedUserId,currentUserId, actualUserName, userstalkingtoo, setlatestMessageTimestamp }) {



    // Remove any existing event listeners to avoid duplicate events
    const sendButton = document.getElementById('send-message-button');
    sendButton.removeEventListener('click', handleSendMessage); // Remove previous listener if any
    sendButton.addEventListener('click', () => handleSendMessage({selectedUserId,currentUserId, actualUserName, userstalkingtoo, setlatestMessageTimestamp }));

    function handleSendMessage({selectedUserId,currentUserId, actualUserName, userstalkingtoo, setlatestMessageTimestamp }) {
      const message = document.getElementById('message-input').value;
      if (message && selectedUserId !== -1) {
        console.log("Sending message: ", message);
        // const fileInput = document.getElementById('file-input');
        // let img;  
  
        const values = [currentUserId, selectedUserId, message];
        const query = `
          INSERT INTO messages (sender_id, receiver_id, messages)
          VALUES (%s, %s, %s)  
        `;
        fetch(hostLink + '/SpecialQueryCommit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json' // Set the content type to JSON
          },
          body: JSON.stringify({ 
            query: query, 
            var: values // Pass values as an array
          })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json(); // Parse the JSON response
        })
        .then(user => {
          // Assuming 'user' contains some info about the sent message
          console.log('Message sent successfully:', user);
          document.getElementById('message-input').value = ''; // Clear input

          loadMessages(false, [], {selectedUserId,currentUserId, setlatestMessageTimestamp }); // Reload messages after sending
        })
        .catch(error => {
          console.error('Error sending message:', error); // Handle errors
        });
      } else {
        console.warn('Message is empty or no user selected.'); // Handle invalid input
        console.warn(message + " " + selectedUserId);
      }
    }
  }


export default SendMessage;