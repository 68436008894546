import React, {useState, useEffect} from "react";
import './HowItWorks.css';

const HowItWorks = ({open, reset}) =>
{



    if (!open) return null;
    return (
        <div className="modal-overlay" onClick={() => reset(false)}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-left-border"></div>
                <button className="close-button" onClick={() => reset(false)}>X</button>
                <div className="insight-analyser-container">
                    <h1 className="title">Comment fonctionne l'Analyseur d'Echowave ?</h1>
                    <div className="description">
                        <p>
                            Echowave vous permet de rester facilement informé sur l'évolution des données de vos réseaux.
                            Vous aurez accès à des tableaux, des graphiques et un générateur PDF pour des données détaillées.
                        </p>
                    </div>
                    <h2 className="subtitle">Comment faire ?</h2>
                    <div className="steps">
                        <div className="description">
                            <p><strong>Étape 1 :</strong> Connectez votre compte Instagram dans la section "Compte".</p>
                            <img className="placeholder-image" src="/Images/Echowave/HowTo/ConnectYourAccount.png" alt="Placeholder" />
                        </div>
                        
                        <div className="description">
                            <p><strong>Étape 2 :</strong> Allez dans la section "Réseau" et cliquez sur "Générer des données mensuelles". Vous serez invité à lier votre compte YouTube.</p>
                            <img className="placeholder-image" src="/Images/Echowave/HowTo/GenerateDatas.png" alt="Placeholder" />
                        </div>
                        
                        <div className="description">
                            <p><strong>Étape 3 :</strong> Vos données seront à jour et prêtes pour l'analyse !</p>
                            <img className="placeholder-image" src="/Images/Echowave/HowTo/Result.png" alt="Placeholder" />
                        </div>
                    </div>
                </div>

                <div className="modal-header">
                </div>
            </div>
        </div>
    );
}

export default HowItWorks;
