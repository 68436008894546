import Cookies from "js-cookie";
import axios from 'axios';
import { createCustomer } from "./StripeCommunication";
import { GetCompanyMail } from "../GatherUserInformationDB/GatherDatabaseUser";

export const hostLink = "https://pulsebackend.onrender.com";
//export const hostLink = "http://127.0.0.1:5000";


const DoesUserHasEchoWave = async (username, password) =>
{
    if (username && password) {
        try {
            const getData = await axios.post(hostLink + '/GatherUserInformation', {
                username: username,
                password: password,
            });
            const custId = await createCustomer(getData.data.data[4]);
            console.log(getData.data.data[4]);
            const id = custId.customerId;

            if(!id)
            {
                return false;
            }

            const response = await fetch(hostLink + '/GetUserIsSubEchowave', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 'customer_id': id }),
            });
    
            // Await the response and parse the JSON data
            const fnlrsp = await response.json();

            // Check if the API response indicates the credentials are valid
            return fnlrsp.hasaccess; // If isValid is true, return it; otherwise, return false
        } catch (error) {
            console.error("Error during API call:", error);
            return false; // Return false in case of an error
        }
    }
    console.log("return false");
    return false; // Return false if username or password is not provided
}

const IsUserValid = (username) =>
{
    return true;
}
const IsUserCredentialValid = async (username, password) => {
    if (username && password) {
        try {
            const response = await axios.post(hostLink + '/IsUserCredValid', {
                username,
                password,
            });

            // Check if the API response indicates the credentials are valid
            return response.data.isValid || false; // If isValid is true, return it; otherwise, return false
        } catch (error) {
            console.error("Error during API call:", error);
            return false; // Return false in case of an error
        }
    }
    console.log("return false");
    return false; // Return false if username or password is not provided
};

const isCookieCredentialStillValid = async () => {
    const usernameToken = Cookies.get('authPulseUsername');
    const passwordToken = Cookies.get('authPulsePassword');

    // Await the result of the async function
    const isValid = await IsUserCredentialValid(usernameToken, passwordToken);

    if (isValid) {
        return true;        
    }
    console.log(isValid);
    return false;
};

const CreateAccount = async(formData) =>
{
    return await axios.post(hostLink + '/CreateAccount', formData);

}

const ChangePasswordDt = async(oldPass, newPass, username) =>
{
    const response = await axios.post(hostLink + "/ChangePassword", {oldPass: oldPass, newPass: newPass, username: username});

    return response
}


const GetActualUserId = async () => {
    try {
        // Fetch stored credentials securely
        const username = Cookies.get('authPulseUsername');
        const password = Cookies.get('authPulsePassword');
        
        if (!username || !password) {
            return {
                hasaccess: false,
                message: 'Missing credentials',
            }; 
        }

        // Make a secure request to gather user information
        const resp = await fetch(`${hostLink}/GatherUserInformation`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        if (!resp.ok) {
            // Handle HTTP errors securely
            const errorData = await resp.json();
            return {
                hasaccess: false,
                message: `Error fetching user data: ${errorData.message || 'Unknown error'}`,
            };
        }

        const userData = await resp.json();
        console.log(new Date(new Date(userData.data[7]).getTime()+ 30 * 24 * 60 * 60 * 1000))
        console.log(new Date())
        if (new Date(new Date(userData.data[7]).getTime()+ 30 * 24 * 60 * 60 * 1000) > new Date() )
            {
                console.log("still in trial");
                return {
                    hasaccess: true,
                    message: `still in trial period. Accessing Echowave.`,
                };
            }
        if (!userData || userData.error) {
            return {
                hasaccess: false,
                message: `User not found or error in response: ${userData?.error || 'No data'}`,
            };
        }

        console.log("User data fetched:", userData.data[4]); // Log non-sensitive data only

        // Assuming userData.data[4] contains relevant information
        const companyData = await GetCompanyMail();
        console.log(companyData);
        const result = await createCustomer(companyData.mail);

        if (result && result.customerId) {
            console.log("Customer ID created:", result.customerId);
            return result.customerId;
        } else {
            return {
                hasaccess: false,
                message: 'Failed to create customer',
            };
        }
    } catch (error) {
        return {
            hasaccess: false,
            message: `An unexpected error occurred: ${error.message}`,
        };
    }
};

export { DoesUserHasEchoWave, IsUserValid, isCookieCredentialStillValid, IsUserCredentialValid, CreateAccount, ChangePasswordDt, GetActualUserId };