import React, { useState, useEffect } from "react";
import '../../CircularGraphHours/CircularGraphHours.css';
import YoutubeGeneralDataTable from "./YoutubeGeneralDataTable";
import { GetUserCompanyData, GetYoutubeData } from "../../../../GatherUserInformationDB/GatherDatabaseUser";
import './youtube.css';
import YoutubeDailyDataTable from "./YoutubeDailyData";
import YoutubeViewDataTable from "./YoutubeViewData";
import ChartModule from "../../../chartMaker/ChartModule";
import LoadingSpinner from "../../../../loading/LoadingSpinner";


const OvertimeAnalyseTableYoutube = () => 
{
    const [activeTable, setActiveTable] = useState('general'); // State to track the active table
    const [companyData, setCompanyData] = useState(null); // State to store the fetched data

    const renderTable = () => {
        switch (activeTable) {
            case 'general':
                return <YoutubeGeneralDataTable />;
            case 'Specific data':
                return <YoutubeDailyDataTable />
            case 'viewData':
                return <YoutubeViewDataTable />
            default:
                return <YoutubeGeneralDataTable />;
        }
    };

    const GetCompData = async() =>
    {        
        const compData = await GetUserCompanyData();
        console.log(compData)
        setCompanyData(compData);
    }
    useEffect(() => 
        {
            GetCompData();
        }, []); // Empty dependency array ensures this runs once on component mount
    
    const DisplayDatas = () =>
    {

    }
    const getTotalMonths = (startDate, endDate) => 
        {
            const start = new Date(startDate);
            const end = new Date(endDate);
            const months = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
            return months;
        }
        
        // Function to format the date (e.g., "January 2024")
        const formatDate = (dateStr) => 
        {
            const date = new Date(dateStr);
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        }


    return (
        <div className="containerNetwork">
            <h1>Quelle est votre évolution au fil du temps sur Youtube ?</h1>
            <hr />
            <h3>Voici toutes vos données dans un tableau au fil du temps.</h3>

            <h1>Analyse global</h1>
            { <div>
                {companyData ? (
                    <div className="youtubeChannelInsights grid">
                        {/* <div className="insight-card">
                            <i className="fas fa-calendar icon"></i> 
                            <p>Période des Insights de YouTube</p>
                            <span className="highlight">
                                Durée totale : {getTotalMonths(companyData[0]["daily_data"][0]["date"], companyData[0]["daily_data"][companyData[0]["daily_data"].length - 1]["date"])} mois
                            </span>
                            <span className="highlight">
                                De : {formatDate(companyData[0]["daily_data"][0]["date"])}
                            </span>
                            <span className="highlight">
                                À : {formatDate(companyData[0]["daily_data"][companyData[0]["daily_data"].length - 1]["date"])}
                            </span>
                        </div> */}

    <div className="insight-card">
    <i className="fas fa-clock icon"></i>
            <p>Durée moyenne de visionnage</p>
        <span className="highlight">
            {companyData[0]["average_view_duration"].toFixed(2)} secondes
        </span>
        <progress value={companyData[0]["average_view_duration"]} max="300"></progress>
    </div>
    
    <div className="insight-card">
    <i className="fas fa-percentage icon"></i>
            <p>Pourcentage moyen de visionnage</p>
        <span className="highlight">
            {companyData[0]["average_view_percentage"].toFixed(2)}%
        </span>
        <progress value={companyData[0]["average_view_percentage"]} max="100"></progress>
    </div>
    
    <div className="insight-card">
    <i className="fas fa-user-plus icon"></i>
            <p>Abonnés gagnés</p>
        <span className="highlight">
            {companyData[0]["total_subscribers_gained"]} abonnés
        </span>
    </div>
    
    <div className="insight-card">
    <i className="fas fa-user-minus icon"></i>
            <p>Abonnés perdus</p>
        <span className="highlight">
            {companyData[0]["total_subscribers_lost"]} abonnés
        </span>
    </div>
    
    <div className="insight-card">
    <i className="fas fa-eye icon"></i>
            <p>Total des vues</p>
        <span className="highlight">
            {companyData[0]["total_views"]} vues
        </span>
    </div>
    
    <div className="insight-card">
    <i className="fas fa-stopwatch icon"></i>
        <p>Total des minutes regardées</p>
        <span className="highlight">
            {companyData[0]["total_minutes_watched"]} minutes
        </span>
    </div>
    
    <div className="insight-card">
        <i className="fas fa-thumbs-up icon"></i>
        <p>Total des j'aime</p>
        <span className="highlight">
            {companyData[0]["total_likes"]} j'aime
        </span>
    </div>
    
    <div className="insight-card">
        <i className="fas fa-thumbs-down icon"></i>
        <p>Total des j'aime pas</p>
        <span className="highlight">
            {companyData[0]["total_dislikes"]} j'aime pas
        </span>
    </div>
    
    <div className="insight-card">
        <i className="fas fa-comment icon"></i>
        <p>Total des commentaires</p>
        <span className="highlight">
            {companyData[0]["total_comments"]} commentaires
        </span>
    </div>
    
    <div className="insight-card">
        <i className="fas fa-share-alt icon"></i>
        <p>Total des partages</p>
        <span className="highlight">
            {companyData[0]["total_shares"]} partages
        </span>
    </div>
    {/* You can also add a chart for daily data */}
    {/* <div className="chart-container">
        <h3>Données quotidiennes et engagement</h3>
        <canvas id="youtubeChannelChart"></canvas>
    </div> */}
</div>

                ) : (
                    <div>Chargement des données de l'entreprise...</div>
                )}
            </div> }
            {/* Buttons to switch between tables */}
            <div className="tableSwitcher">
                <button
                    className={activeTable === 'general' ? 'active' : ''}
                    onClick={() => setActiveTable('general')}
                >
                    Données générales
                </button>
                <button
                    className={activeTable === 'Specific data' ? 'active' : ''}
                    onClick={() => setActiveTable('Specific data')}
                >
                    Données d'interaction
                </button>
                <button
                    className={activeTable === 'viewData' ? 'active' : ''}
                    onClick={() => setActiveTable('viewData')}
                >
                    Données de visionnage
                </button>
            </div>

            {/* Render the active table based on the selected button */}
            <div className="tableContent">
                {renderTable()}
            </div>
        </div>
    );
};

export default OvertimeAnalyseTableYoutube;


export function GenerateCellStyle() {
    return (params) => {
        const currentValue = parseFloat(params.value);
        const rowIndex = params.node.rowIndex;
        // Access the previous row's value using the row index
        if (rowIndex === 0) {
            return { backgroundColor: 'white' }; // First row, no previous value
        }

        const previousRow = params.api.getDisplayedRowAtIndex(rowIndex - 1);
        const previousValue = previousRow ? parseFloat(previousRow.data.instagram_insta_impressions) : null;
        params.value = `${previousValue - currentValue} | ${currentValue}`;

        // Apply conditional coloring based on comparison
        if (previousValue !== null) {
            if (previousValue < currentValue) {
                return { backgroundColor: 'lightgreen' };
            } else if (previousValue > currentValue) {
                return { backgroundColor: 'lightcoral' };
            } else {
                return { backgroundColor: 'white' };
            }
        }
        return null; // Default case
    };
}
