import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({title, labels, data, type= "pie"}) => {
    const chartData = {
        labels: labels, // Example countries
        datasets: [
            {
                label: title,
                data: data, // Pass data as props 
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)', // France
                    'rgba(255, 99, 132, 0.2)', // Germany
                    'rgba(255, 206, 86, 0.2)', // Belgium
                    'rgba(54, 162, 235, 0.2)', // Japan
                    'rgba(153, 102, 255, 0.2)' // USA
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)', 
                    'rgba(255, 99, 132, 1)', 
                    'rgba(255, 206, 86, 1)', 
                    'rgba(54, 162, 235, 1)', 
                    'rgba(153, 102, 255, 1)'
                ],
                borderWidth: 1
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            }
        }
    };

    return (
        <div className="insight-card large">
            <h3>{title}</h3>
            {type == "pie" && (<Pie data={chartData} options={options} />)}
            {type == "bar" && (<Bar data={chartData} options={options} />)}
        </div>
    );
};

export default BarChart;
