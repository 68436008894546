import React, { useState } from "react";
import AnalyzePanel from "./AnalyzePanel";
import "./chartAnalyzer.css";
import { type } from "./AnalyzePanel";



const SocialMediaAnalysis = ({ data, isOpen, setIsOpen }) => {
    const [selectedKeys, setSelectedKeys] = useState(new Set());

    if (!isOpen) return null;

    const handleCheckboxChange = (key) => {
        setSelectedKeys((prevState) => {
            const newSelectedKeys = new Set(prevState);
            if (newSelectedKeys.has(key)) {
                newSelectedKeys.delete(key);
            } else {
                newSelectedKeys.add(key);
            }
            return newSelectedKeys;
        });
    };

    return (
        <div className="modal-overlay" onClick={() => setIsOpen(false)}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h2>Analyse Spécialisée</h2>
                </div>
                <h3>Sélectionner les données à afficher</h3>
                <div className="left-panel">
                    {data && data.length > 0 ? (
                        Object.keys(data[0]).map((key) => {
                            if (key.endsWith("_prediction") || key === "date" || key === "id") {
                                return null;
                            }
                            return (
                                <div key={key} className="checkbox">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            checked={selectedKeys.has(key)}
                                            onChange={() => handleCheckboxChange(key)}
                                            className="checkbox-input"
                                        />
                                        <span className="checkbox-button">{key}</span>
                                    </label>
                                </div>
                            );
                            
                        })
                    ) : (
                        <p className="no-data-message">Aucune donnée à afficher</p>
                    )}
                </div>

                <div className="scrollable-div">
                    <hr />
                    <div>
                        <div className="subtitle">Quelques analyses sur les données :</div>
                        <div className="modal-body-spe">
                            {/* Panels in a 3-column grid */}
                            <AnalyzePanel
                                title="Total des données"
                                data={data}
                                selectedKeys={selectedKeys}
                                typePanel={type.TOTAL}
                            />
                            <AnalyzePanel
                                title="Évolution en % des données"
                                data={data}
                                selectedKeys={selectedKeys}
                                typePanel={type.EVOLUTION}
                            />
                            <AnalyzePanel
                                title="Comparaison avec les prédictions"
                                data={data}
                                selectedKeys={selectedKeys}
                                typePanel={type.COMPARE_PRED}
                            />
                            <AnalyzePanel
                                title="Minimum par données"
                                data={data}
                                selectedKeys={selectedKeys}
                                typePanel={type.MIN_VALUE}
                            />
                            <AnalyzePanel
                                title="Maximum par données"
                                data={data}
                                selectedKeys={selectedKeys}
                                typePanel={type.MAX_VALUE}
                            />
                            <AnalyzePanel
                                title="Valeur médiane par données"
                                data={data}
                                selectedKeys={selectedKeys}
                                typePanel={type.MEDIAN_VALUE}
                            />
                            <div className="grid-item-large">
                            <AnalyzePanel
                                title="Evolution total par date"
                                data={data}
                                selectedKeys={selectedKeys}
                                typePanel={type.EVOLUTION_LIST}
                            />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="subtitle">Analyse des données global :</div>
                    <hr />
                    <hr />
                    <div className="subtitle">Comparaison entre données :</div>
                    <hr />
                    <div className="modal-body-spe"></div>
                </div>
            </div>
        </div>
    );
};

export default SocialMediaAnalysis;
