import React, { useState } from 'react';
import './ExpandableModule.css';

const ExpandableModule = ({ title, content, othersExp }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleContent = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="expandable-module">
            <div className="module-header" onClick={toggleContent}>
                <h3 className="module-title">{title}</h3>
                <span className="toggle-icon">{isOpen ? '-' : '+'}</span>
            </div>
            <div className={`module-content ${isOpen ? 'open' : ''}`}>
                {/* Render HTML content */}
                <div 
                    className="module-inner-content"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
                
                {othersExp && othersExp.length > 0 && (
                    <div className="nested-modules">
                        {othersExp.map((item, index) => (
                            <ExpandableModule
                                key={index}
                                title={item.title}
                                content={item.content}
                                othersExp={item.othersExp}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExpandableModule;
