import { hostLink } from "../DatabaseCommunication/GetDataFromUser";
import Cookies from "js-cookie";

async function GetUserDatas()
{
    const response = await fetch(hostLink + '/GatherUserInformation', {
        method: 'POST',  // POST should be uppercase
        headers: {
            'Content-Type': 'application/json' // Set the content type to JSON
        },
        body: JSON.stringify({
            username: Cookies.get("authPulseUsername"),
            password: Cookies.get("authPulsePassword")  
        }) // Convert the body to a JSON string
    });
    
    const data = await response.json();

    if (data.data)
    {
        return data.data;
    }
}

async function GetUserCompany(username, password)
{
    const response = await fetch(hostLink + '/GatherUserInformation', {
        method: 'POST',  // POST should be uppercase
        headers: {
            'Content-Type': 'application/json' // Set the content type to JSON
        },
        body: JSON.stringify({
            username: username,
            password: password  
        }) // Convert the body to a JSON string
    });


    const data = await response.json();

    if (data.data)
    {
        return data.data[6];
    }
    else
    {
        return 0;
    }
}

async function GetUserCompanyAccessCode()
{
    const company = await GetUserCompany(Cookies.get("authPulseUsername"), Cookies.get("authPulsePassword"));
    
    const compIdResponse = await fetch(hostLink + '/GetCompAccessCode', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            compName: company
        })
    });
    const result = await compIdResponse.json();
    return result.code;
}

async function GetUserCompanyData()
{
    const compIdResponse = await GetCompanyId();
    if (compIdResponse.ok) {
        const compIdData = await compIdResponse.json();
        const compId = compIdData.id;

    
        const dataNetworksResponse = await fetch(hostLink + '/GetCompanyData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                compId: compId
            })
        });
    
        if (dataNetworksResponse.ok) {
            const dataNetworks = await dataNetworksResponse.json();
            let result = [];

            dataNetworks.data.forEach(Element => {
                result.push(JSON.parse(Element));
            });
            return result;
        } else {
            console.error('Failed to fetch company data:', dataNetworksResponse.status);
        }
    
    } else {
        console.error('Failed to fetch company ID:', compIdResponse.status);
    }
}

const GetCompanyMail = async () =>
{
    const company = await GetUserCompany(Cookies.get("authPulseUsername"), Cookies.get("authPulsePassword"));
    console.log(company);
    const compEmail = await fetch(hostLink + '/GetCompMail', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            compName: company
        })
    });
    const mail = await compEmail.json();
    return mail;
}

async function GetCompanyId() {
    const company = await GetUserCompany(Cookies.get("authPulseUsername"), Cookies.get("authPulsePassword"));
    console.log(company);
    const compIdResponse = await fetch(hostLink + '/GetCompId', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            compName: company
        })
    });
    return compIdResponse;
}

async function GetYoutubeData()
{
    const company = await GetUserCompany(Cookies.get("authPulseUsername"), Cookies.get("authPulsePassword"));
    const compIdResponse = await fetch(hostLink + '/GetCompId', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            compName: company
        })
    });

    if (compIdResponse.ok) {
        const compIdData = await compIdResponse.json();
        const compId = compIdData.id;

    
        const dataNetworksResponse = await fetch(hostLink + '/GetCompanyYoutubeData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                compId: compId
            })
        });

        const dataNetworks = await dataNetworksResponse.json();
        let result = [];
        dataNetworks.data.forEach(Element => {
            result.push(JSON.parse(Element));
        });
        return result;

    }
}


export {GetUserCompany, GetUserCompanyData, GetUserDatas, GetUserCompanyAccessCode, GetYoutubeData, GetCompanyId, GetCompanyMail};