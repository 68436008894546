import React from "react";
import Header from "../EchowaveLandingPage/components/Header";
import Footer from "../EchowaveLandingPage/components/Footer";
import ConnectionModule from "./Connection/ConnectionModule";
import "./AccountLandingPage.css";



const AccountPage = () =>
{
    return (
        <section className="containerAccount">
            <Header/>
                <ConnectionModule/>
            <Footer/>
        </section>
    );
}

export default AccountPage;