import React from "react";
import { useState, useEffect} from "react";
import '../CircularGraphHours/CircularGraphHours.css';
import { GetUserCompanyData } from "../../../GatherUserInformationDB/GatherDatabaseUser";
import { GenerateCellStyle } from "./OvertimeAnalyseTable";
import CreateTable from "./TableClass";

const ProductDataTable = () =>
{        const [rowTableGeneral, setRowTableGenerale] = useState([]);
    const [colTableGeneral, setColTableGeneral] = useState([]);

    const GetDatas = async() =>
    {    
        let allRow = [];
        let finalDataCol = [];
        finalDataCol.push({field: 'id'})
        
        finalDataCol.push({
            field: 'instagram_insta_impressions',
            cellStyle: GenerateCellStyle()
        });

        finalDataCol.push({field: "instagram_product_click", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_product_save", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_product_view", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_product_click_prediction", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_product_save_prediction", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_product_view_prediction", cellStyle: GenerateCellStyle()})

        const compData = await GetUserCompanyData();
        compData.forEach((list, index) =>
        {
            let finalDataRow = {};
            console.log(list);
            finalDataRow['id'] = index;
            finalDataRow['instagram_product_click'] = list['instagram_product_click'];
            finalDataRow['instagram_product_save'] = list['instagram_product_save'];
            finalDataRow['instagram_product_view'] = list['instagram_product_view'];
            finalDataRow['instagram_product_click_prediction'] = list['instagram_product_click_prediction'] ? list['instagram_product_click_prediction']  : 0;
            finalDataRow['instagram_product_save_prediction'] = list['instagram_product_save_prediction'] ? list['instagram_product_save_prediction'] : 0;
            finalDataRow['instagram_product_view_prediction'] = list['instagram_product_view_prediction'] ? list['instagram_product_view_prediction'] : 0;
        
            allRow.push(finalDataRow);
        });

        setColTableGeneral(finalDataCol);
        setRowTableGenerale(allRow);
    }

    useEffect(() => 
    {
        GetDatas();
    }, []);
    return (
        <div>
            <h1>product data</h1>
            <CreateTable col= {colTableGeneral} row={rowTableGeneral}/>
    </div>
    );
}

export default ProductDataTable;