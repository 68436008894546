import React, { useEffect, useState } from 'react';
import "./AccountCreation.css"
import { CreateAccount } from '../../DatabaseCommunication/GetDataFromUser';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";


const CreateAccountModal = ({ isOpen, closeModal }) => 
{    
    const navigate = useNavigate(); // Hook pour la navigation
    const [formData, setFormData] = useState({
        username: '',
        identifiant: '',
        email: '',
        password: '',
        company: '',
        accessCode : '',
        isOwner : false
    });

    const verifyFormData = () => 
        {
        for (let key in formData) 
            {
            if (formData[key] === '') 
                {
                return key;
            }
        }
        return -1;
    };

    const [isOwner, SetIsOwner] = useState(false);
    const [isIndividual, setIsIndividual] = useState(false);
    const [isAccessValid, setIsAccessValid] = useState(true); // Pour stocker le résultat de la validation d'accès

     
    // Gérer le changement de la case à cocher
    const handleCheckboxChange = () => {
        SetIsOwner(!isOwner); // Bascule de l'état isOwner
    };

    const handleInputChange = (e) => 
    {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCreateAccount = async() => 
    {
        // Gérer la création du compte
        console.log('Compte créé avec les données:', formData);
        const outputDiv = document.getElementById('outputForm');
        outputDiv.textContent = "";

        formData.isOwner = isOwner;     
        const key = verifyFormData();   
        if(isIndividual)
        {
            formData.isOwner = true;
            isOwner = true;
            formData.company = formData.username;
        }
        if(key != -1)
        {
            if(key === "accessCode")
            {
                if(!isOwner)
                {
                    outputDiv.textContent=`Le champ ${key} ne peut pas être vide`;
                    return;
                }
            }
            else
            {
                outputDiv.textContent=`Le champ ${key} ne peut pas être vide`;
                return;

            }
        }

        const reponse = await CreateAccount(formData);

        outputDiv.textContent = reponse.data.message;
        if (reponse.status == 201) 
        {
            closeModal();
            setTimeout(() => {
                Cookies.set('authPulseUsername', formData.identifiant, {expires: 7});
                Cookies.set('authPulsePassword', formData.password, {expires: 7});
                navigate("/Echowave");                
            }, 1500);

        }
    };

    if (!isOpen) 
    {
        return null;
    }

    return (
<div className="modalOverlay">
    <div className={`createAccountModal ${isIndividual ? "modalSmall" : "modalLarge"}`}>
        {/* Left Section with Slide Animation */}
        <div className="modalLeft">
            <img
                src={isIndividual 
                    ? "https://img.freepik.com/free-vector/telecommuting-concept-illustration_114360-1600.jpg?t=st=1733758447~exp=1733762047~hmac=519984675362a520422fd15415a0091925962fd0fde9e7d58b37de9a3124f500&w=1380" 
                    : "https://img.freepik.com/free-vector/office-concept-illustration_114360-1248.jpg?t=st=1733758416~exp=1733762016~hmac=f229df466ec89ea44b2af8306bb87be73ad510d2146a0a2ba33edf7167321a6a&w=1380"}
                alt="Illustration"
                className={`modalImage ${isIndividual ? "slideInLeft" : "slideInRight"}`}
            />
        </div>

        {/* Right Section */}
        <div className="modalRight">
            <h2 className="createAccountHeader">Créer un compte</h2>

            {/* Toggle between Individual and Entreprise */}
            <div className="accountTypeToggle">
                <button
                    className={`toggleButton ${isIndividual ? "active" : ""}`}
                    onClick={() => setIsIndividual(true)}
                >
                    Particulier
                </button>
                <button
                    className={`toggleButton ${!isIndividual ? "active" : ""}`}
                    onClick={() => setIsIndividual(false)}
                >
                    Entreprise
                </button>
            </div>

            {/* Form Fields */}
            <div className={`formContainer ${isIndividual ? "fadeIn" : "fadeOut"}`}>
                <div className="accountField">
                    <label>Nom d'utilisateur</label>
                    <input
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleInputChange}
                        className="accountInputField"
                        placeholder="Entrez votre nom d'utilisateur"
                    />
                </div>

                <div className="accountField">
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="accountInputField"
                        placeholder="Entrez votre email"
                    />
                </div>

                <div className="accountField">
                    <label>Mot de passe</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        className="accountInputField"
                        placeholder="Entrez votre mot de passe"
                    />
                </div>

                {/* Conditional Fields for Entreprise */}
                {!isIndividual && (
                    <>
                        <div className="accountField fadeIn">
                            <label>Nom de l'entreprise</label>
                            <input
                                type="text"
                                name="company"
                                value={formData.company}
                                onChange={handleInputChange}
                                className="accountInputField"
                                placeholder="Entrez le nom de l'entreprise"
                            />
                        </div>
                        <div className="accountField fadeIn">
                            <label>Code d'accès de l'entreprise</label>
                            <input
                                type="text"
                                name="accessCode"
                                value={formData.accessCode}
                                onChange={handleInputChange}
                                className="accountInputField"
                                placeholder="Entrez le code d'accès"
                            />
                        </div>
                    </>
                )}
            </div>

            <div id="outputForm"></div>

            <button onClick={handleCreateAccount} className="createAccountButton">Créer le compte</button>
            <button onClick={closeModal} className="closeModalButton">Fermer</button>
        </div>
    </div>
</div>



    );
    
};

export default CreateAccountModal;
