const ProjectCard = ({ project, openProject }) => {

  const getProgressPercentage = () => {
    const eventsDone = project.events.filter((event) => event.date < new Date()).length;
    const tasksDone = project.tasks.filter((task) => task.isDone).length;
    const totalDone = eventsDone + tasksDone;
    const total = project.events.length + project.tasks.length;
    return total > 0 ? (totalDone / total) * 100 : 0;
  };

  const eventToday = project.events.filter((event) => {
    const eventDate = new Date(event.date);
    const today = new Date();
    return (
      eventDate.getFullYear() === today.getFullYear() &&
      eventDate.getMonth() === today.getMonth() &&
      eventDate.getDate() === today.getDate()
    );
  }).length;

  return (
    <div className="cardProject" onClick={() => (openProject(project))}>
      <div className="header">
        <h3>{project.title}</h3>
        <p>{project.content}</p>
        <div>
          {/* Résumé des événements et tâches */}
          {project.events.filter((event) => event.date < new Date()).length}/{project.events.length} events done and
          {project.tasks.filter((task) => task.isDone).length}/{project.tasks.length} tasks done
          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${getProgressPercentage()}%` }}></div>
          </div>
        </div>
        <div className={eventToday > 0 ? "highlighted-event" : "normal-event"}>
          {eventToday > 0 ? `${eventToday} events today.` : 'No event today.'}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
