import React, { useEffect, useState } from 'react';
import Cookies from "js-cookie";
import { DoesUserHasEchoWave, IsUserCredentialValid, ChangePasswordDt } from "../../DatabaseCommunication/GetDataFromUser";
import { Link } from "react-router-dom";
import "./UserInformationAccount.css";

// You can import icons from a library like FontAwesome or Material Icons
import { FaCheckCircle, FaCog } from 'react-icons/fa';
import { GetUserDatas } from '../../GatherUserInformationDB/GatherDatabaseUser';

const UserInfAccount = () => {
    const [hasEchoWaveAccess, setHasEchoWaveAccess] = useState(false);
    const [hasTrialPeriod, sethasTrialPeriod] = useState(true);
    const [trialEndDate, setTrialEndDate] = useState(new Date());
    const usernameToken = Cookies.get('authPulseUsername');
    const passwordToken = Cookies.get('authPulsePassword');

    const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => 
    {
        const interval = setInterval(() => 
        {
            const now = new Date();
            const end = new Date(trialEndDate);
            const timeDiff = end - now;

            if (timeDiff <= 0) 
            {
                sethasTrialPeriod(false);
                clearInterval(interval);
            } 
            else 
            {
                sethasTrialPeriod(true);
                const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
                const seconds = Math.floor((timeDiff / 1000) % 60);

                setTimeLeft({ days, hours, minutes, seconds });
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [trialEndDate]);

    useEffect(() => {
        const fetchUserAccess = async () => {
            if (usernameToken && passwordToken) {
                const userData = await GetUserDatas();
                const access = await DoesUserHasEchoWave(usernameToken, passwordToken);
                setHasEchoWaveAccess(access);
                let date = new Date(new Date(userData[7]).getTime()+ 30 * 24 * 60 * 60 * 1000);
                setTrialEndDate(date);

            }
        };

        fetchUserAccess(); // Call the async function
    }, [usernameToken, passwordToken]); // Dependencies array to rerun effect if tokens change


    const ChangePassword = async () =>
    {
        const oldPass = document.getElementById('passwordOld').value;
        const newPass = document.getElementById('passwordNew').value;
        const username = Cookies.get('authPulseUsername');
        const userValid = await IsUserCredentialValid(username, oldPass);
        const outputDiv = document.getElementById('changePswOutput');

        if (userValid)
        {
            const result = await ChangePasswordDt(oldPass, newPass, username);
            console.log(result);
            if (result.status == 200)
            {
                outputDiv.textContent = result.data.message;
                outputDiv.className = result.data.code ? 'successText' : 'failText';
                if (result.data.code)  Cookies.set("authPulsePassword", newPass);
            }
        }
        else
        {            
            outputDiv.textContent = "Credential error";
            outputDiv.className = 'failText';
        }

    }
    function thanking() {
        return <>
        {hasTrialPeriod && (<div className="time-left">
                                {Object.entries(timeLeft).map(([unit, value]) => (
                                    <div key={unit} className="time-frame">
                                        <div className="time-value">{value}</div>
                                        <div className="time-label">{unit.charAt(0).toUpperCase() + unit.slice(1)}</div>
                                    </div>
                                ))}
                            </div>)}
        {!hasTrialPeriod && (<div>End of trial period ! Not a beta tester yet? Get ahead of the competition by joining now!</div>)}
        </>;
    }

    return (
        <section className="containerAccInf">
            <div className="welcomeCard card">
                <img className="profileImage" src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg" alt="Profile" />
                <h2>Welcome, <strong>{usernameToken}</strong></h2>
                <p>Manage your account settings and explore the features available to you.</p>
            </div>

            <div className="cardsContainer">
                <div className="echoWaveCard card">
                    <FaCheckCircle className="icon" />
                    <h3>Echowave Beta Access</h3>
                    <p>
                        {hasEchoWaveAccess
                            ? "Thank you for being one of our beta testers! You have full access to the app."
                            : thanking()}
                    </p>
                    {!hasEchoWaveAccess && (
                        <Link to="/getaccessechowave">
                            <button className="cta-button">Get Started</button>
                        </Link>
                    )}
                </div>
            </div>
            <hr/>

            <div className="cardsContainer">
                <div className="accountInfoCard card">
                    <FaCog className="icon" />
                    <h3>Account Information</h3>
                    <p>Update your profile, password, or other account settings.</p>
                </div>
            </div>
            <div className="cardsContainer">
                <div className="accountInfoCard card">
                    <h3 htmlFor="password">Change password</h3>
                    <div style={{display: 'flex'}}>
                        <input type="password" id="passwordOld" className="inputField" placeholder="Enter your password" />
                        <input type="password" id="passwordNew" className="inputField" placeholder="Enter your new password" />
                    </div>
                    <div id='changePswOutput'></div>
                    <button className="cta-button" id='changePassButton' onClick={ChangePassword}>Change</button>
                </div>
            </div>
        </section>
    );
};


export default UserInfAccount;


