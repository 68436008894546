import React from "react";
import { useState, useEffect} from "react";
import '../../CircularGraphHours/CircularGraphHours.css';
import { GetCompanyId, GetUserCompanyData, GetYoutubeData } from "../../../../GatherUserInformationDB/GatherDatabaseUser";
import { GenerateCellStyle } from "../OvertimeAnalyseTable";
import CreateTable from "../TableClass";
import ChartModule from "../../../chartMaker/ChartModule";
import { hostLink } from "../../../../DatabaseCommunication/GetDataFromUser";

const YoutubeViewDataTable = () =>
{        const [rowTableGeneral, setRowTableGenerale] = useState([]);
    const [colTableGeneral, setColTableGeneral] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [graphLabel, setGraphLabel] = useState([
"youtube_views",
"youtube_estimated_minutes_watched",
"youtube_average_view_percentage",
"youtube_average_view_duration",
    ]);

    const GetDatas = async() =>
    {    
        let allRow = [];
        let finalDataCol = [];
        finalDataCol.push({field: 'date'})
        let graphDataFormatted = [];

        const compData = await GetUserCompanyData();
        finalDataCol.push({field: "youtube_views", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "youtube_estimated_minutes_watched", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "youtube_average_view_percentage", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "youtube_average_view_duration", cellStyle: GenerateCellStyle()})
        Object.entries(compData[0]).forEach(([key, value]) => {
            let finalDataRow = {}; 
            if(!isNaN(new Date(key).getTime()))
            {
                finalDataRow["date"] = key; 
            
                Object.entries(value).forEach(([subKey, subValue]) => {
                    finalDataRow[subKey] = subValue;
                });
                allRow.push(finalDataRow);
                graphDataFormatted.push(finalDataRow);
            }
        });
        allRow.sort((a, b) => 
        {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA - dateB; 
        });
        graphDataFormatted.sort((a, b) => 
        {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA - dateB; 
        });
        setColTableGeneral(finalDataCol);
        setRowTableGenerale(allRow);
        setGraphData(graphDataFormatted);
    }

    useEffect(() => 
    {
        GetDatas();
    }, []);
    return (
        <div>
            <h1>Youtube general data</h1>
            <CreateTable col= {colTableGeneral} row={rowTableGeneral} network="youtube"/>
            <ChartModule
                chartTitle="YouTube View Data Overview"
                dataSets={[graphData]}    // Use the fetched graph data
                chartType="area"
                xField="date"
                yFields={graphLabel}      // Label fields for Y-axis
                theme="ag-pastel"
                height={500}
            />
    </div>
    );
}

export default YoutubeViewDataTable;