import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const GenderDistributionChart = ({ data }) => {


    return (
        <div className="insight-card">
            <h3>Gender Distribution</h3>
            <Pie data={data} />
        </div>
    );
};

export default GenderDistributionChart;
