import React, { useState, useEffect } from 'react';
import './TrialModal.css';

const TrialModal = ({ trialEndDate = '2024-12-31T23:59:59' }) => 
{
    const [isOpen, setIsOpen] = useState(true);
    const [timeLeft, setTimeLeft] = useState({ jours: 0, heures: 0, minutes: 0, secondes: 0 });

    useEffect(() => 
    {
        const interval = setInterval(() => 
        {
            const now = new Date();
            const end = new Date(trialEndDate);
            const timeDiff = end - now;

            if (timeDiff <= 0) 
            {
                clearInterval(interval);
                setIsOpen(false);
            } 
            else 
            {
                const jours = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
                const heures = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
                const secondes = Math.floor((timeDiff / 1000) % 60);

                setTimeLeft({ jours, heures, minutes, secondes });
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [trialEndDate]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={() => setIsOpen(false)}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-left-border"></div>
                <button className="close-button" onClick={() => setIsOpen(false)}>X</button>

                <div className="modal-header">
                    <div className="modal-image-container">
                        <img 
                            src="https://img.freepik.com/free-vector/clock-concept-illustration_114360-1056.jpg"
                            alt="Icône de l'essai" 
                            className="modal-image" 
                        />
                    </div>

                    <div className="modal-scrollable-content">
                        <h2>Période d'essai</h2>
                        <p className="modal-description">
                            Votre accès d'essai se termine dans :
                        </p>
                        <div className="time-left">
                            {Object.entries(timeLeft).map(([unit, value]) => (
                                <div key={unit} className="time-frame">
                                    <div className="time-value">{value}</div>
                                    <div className="time-label">{unit.charAt(0).toUpperCase() + unit.slice(1)}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrialModal;
