import React from "react";
import { useState, useEffect} from "react";
import '../CircularGraphHours/CircularGraphHours.css';
import { GetUserCompanyData } from "../../../GatherUserInformationDB/GatherDatabaseUser";
import { GenerateCellStyle } from "./OvertimeAnalyseTable";
import CreateTable from "./TableClass";
import ChartModule from "../../chartMaker/ChartModule";

const WomenInformationTable = () =>
{        const [rowTableGeneral, setRowTableGenerale] = useState([]);
    const [colTableGeneral, setColTableGeneral] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [graphLabel, setGraphLabel] = useState([]);

    const GetDatas = async() =>
    {    
        let allRow = [];
        let finalDataCol = [];
        finalDataCol.push({field: 'id'})
        const compData = await GetUserCompanyData();
        console.log(compData[0]['instagram_women'])
        compData[0]['instagram_women'].forEach(element => {
            finalDataCol.push({
                field: 'instagram_women_'+element["label"],
                cellStyle: GenerateCellStyle()
            });      
            
        });

        let graphDataFormatted = [];
        let rowName = [];

        compData.forEach((list, index) =>
        {
            let finalDataRow = {};
            finalDataRow["id"] = index;
            list["instagram_women"].forEach(element => {                
                finalDataRow['instagram_women_'+element["label"]] = element["value"];
                finalDataRow["instagram_women_" + element["label"] + "_prediction"] = list["instagram_women_" + element["label"] + "_prediction"] ? list["instagram_women_" + element["label"] + "_prediction"] : 0;

                if(index == 0)
                    {
                        rowName.push(element["label"]);
                    }
            });
    
            allRow.push(finalDataRow);
            graphDataFormatted.push(finalDataRow);
        });

        setColTableGeneral(finalDataCol);
        setRowTableGenerale(allRow);
        setGraphLabel(rowName);
        setGraphData(graphDataFormatted);
    }

    useEffect(() => 
    {
        GetDatas();
    }, []);
    return (
        <div>
            <h1>Women follower age</h1>
            <CreateTable col= {colTableGeneral} row={rowTableGeneral}/>
            <ChartModule
                chartTitle="Instagram Women Data Overview"
                dataSets={[graphData]}    // Use the fetched graph data
                chartType="line"
                xField="id"
                yFields={graphLabel}      // Label fields for Y-axis
                theme="ag-pastel"
                height={500}
            />
    </div>
    );
}

export default WomenInformationTable;