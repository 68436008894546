import { hostLink } from "../DatabaseCommunication/GetDataFromUser";
import { loadMessages } from "./showChatPopup";
async function checkForNewMessages({latestMessageTimestamp, selectedUserId, currentUserId, setlatestMessageTimestamp}) {
    console.log(latestMessageTimestamp);
          fetch(hostLink + '/SpecialQueryGetnoVar', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' // Set the content type to JSON
            },
            body: JSON.stringify({ 
              query: `SELECT * FROM messages WHERE timestamps > '${new Date(latestMessageTimestamp).toISOString()}' AND (sender_id = ${selectedUserId} AND receiver_id = ${currentUserId})  ORDER BY timestamps DESC LIMIT 1`
            })
             // Convert the data to JSON
          })
          .then(response => response.json()) // Parse the JSON response
          .then(result => {
          const newMessages = result;
          if (newMessages.data[newMessages.data.length - 1])
          {
            var newMessageTimestamp = new Date(newMessages.data[newMessages.data.length - 1][4]);
  
            if (new Date(latestMessageTimestamp) < newMessageTimestamp) {
              setlatestMessageTimestamp(newMessageTimestamp); // Update timestamp
                console.log(newMessages.data);
                loadMessages(false, [], {selectedUserId,currentUserId, setlatestMessageTimestamp});
            }
          }
      });
  };
  

  export default checkForNewMessages;