import React, { useState, useEffect } from 'react';
import './Hero.css';
import { Link } from "react-router-dom";

const Hero = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(true);

    // Textes du carrousel avec des mots importants en gras
    const carouselTexts = [
        <>Boostez votre entreprise avec <strong>EchoWave</strong></>,
        <>Restez connecté grâce à la <strong>messagerie en temps réel</strong></>,
        <>Obtenez une <strong>analyse complète</strong> des données de votre réseau</>,
        <>Soyez <strong>productif</strong> avec nos outils, tout-en-un</>
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(false); // Commence la transition de disparition

            setTimeout(() => {
                // Met à jour le texte après la disparition
                setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselTexts.length);
                setFade(true); // Commence la transition d'apparition
            }, 500); // Durée de la transition de disparition (0,5s)
        }, 3000); // Change de texte toutes les 3 secondes

        return () => clearInterval(interval);
    }, [carouselTexts.length]);

    return (
        <section className="hero" id="home">
            <div className="container">
                <h1>Découvrez EchoWave</h1>
                <p className={`carousel-text ${fade ? 'fade-in' : 'fade-out'}`}>
                    {carouselTexts[currentIndex]}
                </p>

                <Link to="/getaccessechowave">
                    <button className="cta-button">Commencer</button>
                </Link>
            </div>
        </section>
    );
}

export default Hero;
