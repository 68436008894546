import React, { useState } from 'react';
import './CompanyFeed.css'; // Ensure the styles are imported

const ProjectModal = ({projectList, setProjects}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [events, setEvents] = useState([]);

  // Function to open the modal
  const handleOpen = () => {
    setIsOpen(true);
  };

  // Function to close the modal
  const handleClose = () => {
    setIsOpen(false);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Gather project data
    const projectTitle = e.target.projectTitle.value;
    const projectContent = e.target.projectContent.value;
    const projectDueDate = e.target.projectDueDate.value;

    // Create new project JSON
    const newProject = {
      id: Date.now(), // Generate a simple ID based on timestamp
      title: projectTitle,
      content: projectContent,
      dueDate: new Date(projectDueDate),
      tasks,
      events
    };

    console.log('New Project:', newProject);

    // Reset and close modal
    e.target.reset();
    handleClose();
    addNewProject(newProject);
  };

  const addNewProject = (newProject) => {
    // Create a new array with the new project added
    newProject.dueDate = new Date(newProject.dueDate);
    let updatedProjects = [...projectList, newProject];

    setProjects(updatedProjects); // Update the state with the new array
  };

  // Add task field
  const addTask = () => {
    setTasks([...tasks, { title: '', desc: '', isDone: false }]);
  };

  // Remove task field
  const removeTask = (index) => {
    setTasks(tasks.filter((_, i) => i !== index));
  };

  // Add event field
  const addEvent = () => {
    setEvents([...events, { title: '', date: '', desc: '', brdColor: '#000000' }]);
  };

  // Remove event field
  const removeEvent = (index) => {
    setEvents(events.filter((_, i) => i !== index));
  };

  // Handle task change
  const handleTaskChange = (index, field, value) => {
    const newTasks = [...tasks];
    newTasks[index][field] = value;
    setTasks(newTasks);
  };

  // Handle event change
  const handleEventChange = (index, field, value) => {
    const newEvents = [...events];
    newEvents[index][field] = value;
    setEvents(newEvents);
  };

  return (
    <>
      {/* Button to open the modal */}
      <button className="open-modal-button button" onClick={handleOpen}>
        Create New Project
      </button>

      {/* Modal for creating a project */}
      {isOpen && (
        <div id="projectModal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleClose}>
              &times;
            </span>
            <h2>Create New Project</h2>

            <form id="projectForm" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="projectTitle">Project Title:</label>
                <input type="text" id="projectTitle" name="projectTitle" required />
              </div>

              <div>
                <label htmlFor="projectContent">Content:</label>
                <input type="text" id="projectContent" name="projectContent" required />
              </div>

              <div>
                <label htmlFor="projectDueDate">Due Date:</label>
                <input type="date" id="projectDueDate" name="projectDueDate" required />
              </div>

              <hr />

              {/* Tasks Section */}
              <h3>Tasks</h3>
              <div id="tasksContainer">
                {tasks.map((task, index) => (
                  <div key={index}>
                    <label>Task Title:</label>
                    <input
                      type="text"
                      className="task-title"
                      value={task.title}
                      onChange={(e) => handleTaskChange(index, 'title', e.target.value)}
                      required
                    />
                    <label>Description:</label>
                    <input
                      type="text"
                      className="task-desc"
                      value={task.desc}
                      onChange={(e) => handleTaskChange(index, 'desc', e.target.value)}
                      required
                    />
                    <label>Is Done:</label>
                    <input
                      type="checkbox"
                      className="task-isDone"
                      checked={task.isDone}
                      onChange={(e) => handleTaskChange(index, 'isDone', e.target.checked)}
                    />
                    <button type="button" className="removeTask" onClick={() => removeTask(index)}>
                      Remove
                    </button>
                  </div>
                ))}
              </div>
              <button type="button" id="addTaskButton" onClick={addTask}>
                Add Task
              </button>

              <hr />

              {/* Events Section */}
              <h3>Events</h3>
              <div id="eventsContainer">
                {events.map((event, index) => (
                  <div key={index}>
                    <label>Event Title:</label>
                    <input
                      type="text"
                      className="event-title"
                      value={event.title}
                      onChange={(e) => handleEventChange(index, 'title', e.target.value)}
                      required
                    />
                    <label>Date:</label>
                    <input
                      type="date"
                      className="event-date"
                      value={event.date}
                      onChange={(e) => handleEventChange(index, 'date', e.target.value)}
                      required
                    />
                    <label>Description:</label>
                    <input
                      type="text"
                      className="event-desc"
                      value={event.desc}
                      onChange={(e) => handleEventChange(index, 'desc', e.target.value)}
                      required
                    />
                    <label>Border Color:</label>
                    <input
                      type="color"
                      className="event-brdColor"
                      value={event.brdColor}
                      onChange={(e) => handleEventChange(index, 'brdColor', e.target.value)}
                      required
                    />
                    <button type="button" className="removeEvent" onClick={() => removeEvent(index)}>
                      Remove
                    </button>
                  </div>
                ))}
              </div>
              <button type="button" id="addEventButton" onClick={addEvent}>
                Add Event
              </button>

              <hr />

              <button type="submit">Save Project</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectModal;
