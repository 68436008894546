import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './LoadingModule.css';

function LoadingModule({ text = "Waiting server response" }) {
    useEffect(() => {
        // Disable scrolling and interactions when the overlay appears
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';
        document.body.style.pointerEvents = 'none';

        // Scroll to top
        window.scrollTo(0, 0);

        // Re-enable when component unmounts
        return () => {
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
            document.body.style.pointerEvents = 'auto';
        };
    }, []);

    return(
        <div className="loading-overlay">
            <div className="loading-circle"></div>
            <div className="loading-text">{text}</div>
        </div>
    );
}

export default LoadingModule;
