import React from 'react';
import Cookies from "js-cookie";
import './GetStartedEchoWave.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { createCustomer, SubScribeToEchowave } from '../../DatabaseCommunication/StripeCommunication';
import { hostLink } from '../../DatabaseCommunication/GetDataFromUser';
import { Navigate, useNavigate } from 'react-router-dom';

const GetStartedEchoWave = () => {
    const navigate = useNavigate(); // Hook pour la navigation
    const HandleCreationUser = async () => {
        try {
            const resp = await fetch(hostLink + '/GatherUserInformation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: Cookies.get('authPulseUsername'),
                    password: Cookies.get('authPulsePassword'),
                }),
            });

            if (!resp.ok) {
                // Gérer les erreurs HTTP
                const errorData = await resp.json();
                Navigate("/Account");
                console.error("Erreur lors de la récupération des données de l'utilisateur :", errorData);
                return;
            }

            const userData = await resp.json();
            console.log("Données utilisateur :", userData.data[4]);

            if (userData.error) {
                Navigate("/Account");
                console.error("Utilisateur non trouvé :", userData.error);
                return;
            }

            // Supposons que userData[5] soit correct, vous devrez peut-être vérifier cela
            const result = await createCustomer(userData.data[4]);
            console.log("ID client :", result['customerId']);
            
            if (result['customerId']) {
                const returned = await SubScribeToEchowave(result['customerId']);
                console.log('URL de souscription :', returned);

                if (returned) {
                    window.location.href = returned; // Rediriger uniquement si l'URL retournée est valide
                } else {
                    console.error("Aucune URL retournée pour la souscription.");
                }
            }
        } catch (error) {
            navigate("/Account");
            console.error("Une erreur est survenue :", error);
        }
    };

    return (
        <section className="get-started-page">
            <Header />
            <div className="get-started-container">
                <div className="intro-section">
                    <h1>Bienvenue sur EchoWave</h1>
                    <p>
                        EchoWave est la plateforme de communication de nouvelle génération conçue pour améliorer
                        votre flux de travail avec une collaboration fluide, des messages en temps réel et
                        une gestion d'équipe intégrée.
                    </p>
                    <h2>Pourquoi rejoindre la bêta ?</h2>
                    <p>
                        En tant qu'utilisateur bêta, vous serez parmi les premiers à découvrir la puissance d'EchoWave.
                        Vous contribuerez à façonner le produit avec vos retours tout en accédant en avant-première
                        aux fonctionnalités premium.
                        <br/><strong style={{color: 'var(--darkerOpositiColor)'}}>En retour, nous vous offrons un tarif spécial
                        réduit pendant la période bêta ! (plus de 50 % de réduction) et 1 mois gratuit à la création de votre compte !</strong>
                    </p>
                </div>
                <hr/>
                <div className="features-section">
                    <h2>Ce qu'EchoWave propose</h2>
                    <ul>
                        <li>Messagerie d'équipe en temps réel</li>
                        <li>Partage de fichiers et outils de collaboration</li>
                        <li>Espaces de travail personnalisés</li>
                        <li>Analyse des données du réseau</li>
                        <li>Infrastructure sécurisée et évolutive</li>
                    </ul>
                </div>

                <div className="subscription-section">
                    <h2>Plans d'abonnement</h2>
                    <p>
                        Abonnez-vous à EchoWave et débloquez toutes les fonctionnalités (et futures) avec un simple abonnement mensuel.
                        Aucun engagement à long terme, annulez à tout moment. Choisissez le plan qui vous convient !
                    </p>
                    <div className="plan">
                        <h3>EchoWave Pro</h3>
                        <p><strong>51,70€/mois</strong></p>
                        <p>Accès complet à toutes les fonctionnalités actuelles et futures.</p>
                    </div>
                </div>

                <div className="cta-section">
                    <h2>Prêt à commencer ?</h2>
                    <p>
                        Ne manquez pas l'offre bêta ! Abonnez-vous maintenant et portez la productivité de votre équipe à un niveau supérieur.
                    </p>
                    <button className='subscribe-button' onClick={HandleCreationUser}>Abonnez-vous maintenant !</button>
                </div>
            </div>
            <Footer />
        </section>
    );
};

export default GetStartedEchoWave;
