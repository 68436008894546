import React, { useState, useEffect, useRef } from 'react';
import "./ActualityNote.css";
import GenerateCardMembers from './GenerateTeamMembersCard';
import SendMessage from './SendMessageHandler';
import Cookies from "js-cookie";
import checkForNewMessages from './CheckNewMsg';
import { hostLink } from '../DatabaseCommunication/GetDataFromUser';

function Team() 
{
    const [currentUserId, setCurrentUserId] = useState(1);
    const [allTeamUsers] = useState([]);
    const [isTeamVisible, setTeamVisible] = useState(false);
    const [selectedUserId, setselectedUserId] = useState(-1);
    const [userstalkingtoo] = useState(-1);
    const [actualUserName] = useState("");
    const [latestMessageTimestamp, setlatestMessageTimestamp] = useState(new Date());
    
    // Ref for the teamContent div
    const teamContentRef = useRef(null);

    const toggleTeamContainer = async() => 
    {        
        const response = await fetch(hostLink + '/GatherUserInformation', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
            username: Cookies.get("authPulseUsername"), 
            password: Cookies.get("authPulsePassword") 
        })
    });
    
    const actualUser = await response.json();
    console.log(actualUser['data'][0]);
    Cookies.set("userId", actualUser['data'][0]);
    setCurrentUserId(actualUser['data'][0]);
        setTeamVisible(!isTeamVisible);
    };

    useEffect(() =>
    {
        const checkMsg = () =>
        {            
            let chatPopup = document.getElementById('chat-popup');
            if (!chatPopup.classList.contains("hidden"))
                {
                    checkForNewMessages({latestMessageTimestamp, selectedUserId, currentUserId, setlatestMessageTimestamp});
                }
        }
        const intervalId = setInterval(checkMsg, 10000);        
        return () => clearInterval(intervalId);
    }, [selectedUserId, currentUserId, latestMessageTimestamp])

    useEffect(() => 
    {
        if (isTeamVisible) 
        {                 
            GenerateCardMembers({currentUserId, allTeamUsers, teamContentRef, selectedUserId, userstalkingtoo, actualUserName, setselectedUserId, setCurrentUserId, setlatestMessageTimestamp});
            let chatPopup = document.getElementById('chat-popup');
            let closeChatBtn = document.getElementById('close-chat-btn');
            closeChatBtn.addEventListener('click', () => 
            {
                console.log("hiding");
                chatPopup.classList.add('hidden');
                chatPopup.classList.remove('visible');
            });

          
        }
    }, [isTeamVisible, actualUserName, allTeamUsers, currentUserId, selectedUserId, userstalkingtoo]); // Trigger effect when isTeamVisible changes

    useEffect(() => 
        {
            if (selectedUserId !== -1) {
                // Perform actions with the updated selectedUserId here
            }
        }, [selectedUserId]); // This effect runs when selectedUserId changes
        useEffect(() => 
            {
                // Perform actions with the updated selectedUserId here
                
            }, [latestMessageTimestamp]); // This effect runs when selectedUserId changes
    
        useEffect(() => 
            {
                if (currentUserId !== -1) {
                    // Perform actions with the updated selectedUserId here
                }
            }, [currentUserId]); // This effect runs when selectedUserId changes


        const SendMsg = () =>
        {
            console.warn("sending a new msg !");
            SendMessage({selectedUserId,currentUserId, actualUserName, userstalkingtoo, setlatestMessageTimestamp });

        }

        

    return (
        <div>
            <button className="team-btn button" onClick={toggleTeamContainer}>Equipe</button>
            {isTeamVisible && (
                <div className="teamContainer">
                    <button className="close-btn" onClick={toggleTeamContainer}>×</button>
                    <div className="mainTitle">Equipe</div>
                    <hr />
                    <div id="teamContent" ref={teamContentRef}>
                    </div>
                </div>
            )}

            
<div id="chat-popup" className="hidden">
    <div id="chat-header">
        <div className="mainTitle">Conversation avec <span id="chat-username"></span></div>
        <button id="close-chat-btn">&times;</button>
    </div>
    <div id="chat-messages" className="message-container">
    </div>
    <div id="chat-form">
        <input type="text" id="message-input" placeholder="Type your message..." />
        <button id="send-message-button" onClick={SendMsg}>Envoyer</button>
    </div>
</div>
        </div>
    );
}

export default Team;
