import React from 'react';
import './Features.css';
import { Link } from 'react-router-dom';
import { FaComments, FaHandshake, FaBolt, FaEnvelope, FaCheckCircle } from "react-icons/fa";

const Features = () => {
    const featureItems = [
        {
            title: "Connectez vos équipes sans complexité",
            description: "Utilisez notre messagerie en temps réel pour une communication fluide et sécurisée.",
            imageUrl: "https://images.unsplash.com/photo-1662974770404-468fd9660389?q=80&w=1932&auto=format&fit=crop"
        },
        {
            title: "Analysez vos réseaux sociaux sans perdre des heures",
            description: "Obtenez des insights sur votre audience et optimisez vos stratégies marketing.",
            imageUrl: "https://images.unsplash.com/photo-1526628953301-3e589a6a8b74?q=80&w=2006&auto=format&fit=crop"
        },
        {
            title: "Utilisez des outils d'analyse en toute simplicité.",
            description: "Obtenez des insights sur votre audience et optimisez vos stratégies marketing.",
            imageUrl: "https://images.unsplash.com/photo-1526628953301-3e589a6a8b74?q=80&w=2006&auto=format&fit=crop"
        },
        {
            title: "Gérez vos projets sans vous perdre",
            description: "Centralisez vos tâches, vos équipes et vos ressources en un seul endroit.",
            imageUrl: "https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?q=80&w=2047&auto=format&fit=crop"
        },
        {
            title: "Générez des rapports détaillés sans effort",
            description: "Générez des rapports clairs et concis pour analyser vos progrès et identifier les axes d'amélioration.",
            imageUrl: "https://images.unsplash.com/photo-1555703102-e937d40b2b04?q=80&w=2047&auto=format&fit=crop"
        }
    ];

    return (
        <section className="features" id="features">
            <div className="container">
                <h2 className="features-title">Faites passer votre entreprise au niveau supérieur</h2>
                <div className="feature-list">
                    {featureItems.map((item, index) => (
                        <div key={index} className="feature-container">
                            <img src={item.imageUrl} alt={item.title} className="feature-image" />
                            <div className="feature-content">
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Section EchoWave grandit avec vous */}

<div className="beta-section">
    <h3 className="beta-title">EchoWave grandit avec vous</h3>
    <p className="beta-description">
        Vous voulez faire partie du processus ? <br />
        Rejoignez notre communauté de <strong style={{ color: 'red' }}>testeurs bêta</strong> avec le premier mois offert !
    </p>
    <hr />
    <h3 className="beta-program-title">Qu’est-ce que le programme Beta Testeur ?</h3>
    <p className="beta-description">
        Intéressé(e) par <strong>aider</strong> à faire de cette application un outil indispensable pour les entreprises ? <br />
        Avec notre programme de testeurs bêta, vous aurez <strong>un accès complet à l’application</strong> en payant 
        <strong className="launching-price" title="Le prix de lancement est de 51,70€/mois"> le prix de lancement</strong> <br />
        Cet accès vous permet :
    </p>
    <ul className="what-offers-list">
        <li><FaCheckCircle size={18} /> <strong>Accès complet à l’app :</strong> Aucun frais supplémentaire, tout est inclus.</li>
        <li><FaHandshake size={18} /> <strong>Donner votre avis :</strong> Partagez vos idées et suggestions.</li>
        <li><FaComments size={18} /> <strong>Communication directe :</strong> Échangez directement avec notre équipe.</li>
        <li><FaBolt size={18} /> <strong>Influencer le développement :</strong> Contribuez à façonner les fonctionnalités que vous souhaitez.</li>
        <li><FaEnvelope size={18} /> <strong>Contributeur futur :</strong> Proposez des idées pour les prochaines versions via notre newsletter.</li>
    </ul>
    <Link to="/getaccessechowave">
        <button className="cta-button">Rejoignez-nous</button>
    </Link>
</div>


            </div>
        </section>
    );
};

export default Features;
