import React, { useEffect, useRef } from "react";
import { AgCharts } from 'ag-charts-community'; // Import de la bibliothèque AG-Chart
import { Link } from "react-router-dom";

export const type = {
    TOTAL: 0,
    EVOLUTION: 1,
    COMPARE_PRED: 2,
    MAX_VALUE: 3, // Ajout de l’analyse pour la valeur maximale
    MIN_VALUE: 4, // Ajout de l’analyse pour la valeur minimale
    MEDIAN_VALUE: 5, // Ajout de l’analyse pour la valeur médiane
    EVOLUTION_LIST: 6, // Liste des évolutions en pourcentage
};

Object.freeze(type);

const AnalyzePanel = ({ title, data, selectedKeys, typePanel }) => {

    const chartRef = useRef(null); // Référence pour le graphique AG-Chart
    const calculateTrend = (key) => {
        const values = data.map((row) => parseFloat(row[key] || 0));
        const firstValue = values[0];
        const lastValue = values[values.length - 1];
        
        if (lastValue > firstValue) {
            return 'Haussière';
        } else if (lastValue < firstValue) {
            return 'Baissière';
        } else {
            return 'Stable';
        }
    };
    const calculateRSI = (key, period = 14) => {
        const values = data.map((row) => parseFloat(row[key] || 0));
        const gains = [];
        const losses = [];
        for (let i = 1; i < values.length; i++) {
            const change = values[i] - values[i - 1];
            if (change > 0) gains.push(change);
            else losses.push(-change);
        }
        const avgGain = gains.slice(0, period).reduce((sum, g) => sum + g, 0) / period;
        const avgLoss = losses.slice(0, period).reduce((sum, l) => sum + l, 0) / period;
    
        let rsi = [];
        let prevAvgGain = avgGain;
        let prevAvgLoss = avgLoss;
    
        for (let i = period; i < values.length; i++) {
            const change = values[i] - values[i - 1];
            const gain = change > 0 ? change : 0;
            const loss = change < 0 ? -change : 0;
    
            const avgGain = (prevAvgGain * (period - 1) + gain) / period;
            const avgLoss = (prevAvgLoss * (period - 1) + loss) / period;
    
            prevAvgGain = avgGain;
            prevAvgLoss = avgLoss;
    
            const rs = avgLoss === 0 ? 100 : avgGain / avgLoss;
            rsi.push({
                index: i,
                value: 100 - 100 / (1 + rs),
            });
        }
    
        return rsi;
    };
    
    const calculateMACD = (key, shortPeriod = 12, longPeriod = 26, signalPeriod = 9) => {
        const values = data.map((row) => parseFloat(row[key] || 0));
    
        const calculateEMA = (period, data) => {
            const multiplier = 2 / (period + 1);
            return data.reduce((ema, price, i) => {
                if (i === 0) return [price];
                const newEma = price * multiplier + ema[i - 1] * (1 - multiplier);
                return [...ema, newEma];
            }, []);
        };
    
        const shortEMA = calculateEMA(shortPeriod, values);
        const longEMA = calculateEMA(longPeriod, values);
    
        const macdLine = shortEMA.map((value, i) => value - longEMA[i]);
        const signalLine = calculateEMA(signalPeriod, macdLine);
        const histogram = macdLine.map((value, i) => value - signalLine[i]);
    
        return {
            macdLine: macdLine.map((value, index) => ({ index, value })),
            signalLine: signalLine.map((value, index) => ({ index, value })),
            histogram: histogram.map((value, index) => ({ index, value })),
        };
    };

    const calculateBollingerBands = (key, period = 20, multiplier = 20) => {
        const values = data.map((row) => parseFloat(row[key] || 0));
        const sma = values.map((_, i) =>
            i >= period
                ? values.slice(i - period, i).reduce((sum, val) => sum + val, 0) / period
                : null
        );
    
        const stdDev = values.map((_, i) =>
            i >= period
                ? Math.sqrt(
                    values
                        .slice(i - period, i)
                        .map((val) => Math.pow(val - sma[i], 2))
                        .reduce((sum, val) => sum + val, 0) / period
                )
                : null
        );
    
        return {
            middleBand: sma.map((value, index) => ({ index, value })),
            upperBand: sma.map((value, index) => ({
                index,
                value: value + multiplier * (stdDev[index] || 0),
            })),
            lowerBand: sma.map((value, index) => ({
                index,
                value: value - multiplier * (stdDev[index] || 0),
            })),
        };
    };
    

    const GetChangePercentList = (keys) => {
        const resultList = [];
    
        keys.forEach((key) => {
            const changes = [];
            for (let i = 1; i < data.length; i++) {
                const previousValue = parseFloat(data[i - 1][key]) || 1;
                const currentValue = parseFloat(data[i][key]) || 1;
                if (isNaN(previousValue) || isNaN(currentValue)) {
                    continue; // Ignorer les valeurs invalides
                }
                const percentChange = ((currentValue - previousValue) / previousValue) * 100;
                changes.push({ index: i, value: percentChange });
            }
            resultList.push({ key, changes });
        });
    
        return resultList;
    };

    // Calculer la moyenne mobile des données précédentes et actuelles
    const getRunningAverage = (key, index) => {
        // On va calculer la moyenne en prenant les valeurs précédentes et celle de l'index actuel
        const values = [];
        if (index > 0) values.push(parseFloat(data[index - 1][key]) || 0);  // Valeur précédente
        values.push(parseFloat(data[index][key]) || 0);  // Valeur actuelle
        const sum = values.reduce((acc, val) => acc + val, 0);
        return sum / values.length;
    };

    // Calculer le total d'une colonne
    const getTotal = (key) => {
        return data.reduce((sum, row) => sum + parseFloat(row[key] || 0), 0);
    };

    // Calculer le pourcentage d'évolution
    const getPercentageChange = (key) => {
        const firstValue = parseFloat(data[0][key]) || 1;
        const lastValue = parseFloat(data[data.length - 1][key]) || 1;
        return ((lastValue - firstValue) / firstValue) * 100;
    };

    // Calculer la différence moyenne avec la prédiction
    const getPredictionDifference = (key) => {
        const differences = data.map((row) => {
            const actual = parseFloat(row[key]) || 0;
            const predicted = parseFloat(row[`pred_${key}`]) || 0;
            const difference = actual - predicted;
            return {
                difference,
                percentageDifference: (difference / predicted) * 100,
            };
        });
        const avgDifference = differences.reduce((sum, diff) => sum + diff.difference, 0) / differences.length;
        const avgPercentageDifference = differences.reduce((sum, diff) => sum + diff.percentageDifference, 0) / differences.length;
        return { avgDifference, avgPercentageDifference };
    };

    // Calculer la valeur maximale d'une colonne
    const getMaxValue = (key) => {
        return Math.max(...data.map((row) => parseFloat(row[key] || 0)));
    };

    // Calculer la valeur minimale d'une colonne
    const getMinValue = (key) => {
        return Math.min(...data.map((row) => parseFloat(row[key] || 0)));
    };

    // Calculer la valeur médiane d'une colonne
    const getMedianValue = (key) => {
        const values = data.map((row) => parseFloat(row[key] || 0)).sort((a, b) => a - b);
        const mid = Math.floor(values.length / 2);
        return values.length % 2 === 0 ? (values[mid - 1] + values[mid]) / 2 : values[mid];
    };

    //chart for the evolution percent
    useEffect(() => {
        if (typePanel === type.EVOLUTION_LIST && chartRef.current) {
            const changes = GetChangePercentList([Array.from(selectedKeys)[selectedKeys.size - 1]]);
            const key = Array.from(selectedKeys)[selectedKeys.size - 1];

            const rsi = calculateRSI(key, key.length);
            const { macdLine, signalLine, histogram } = calculateMACD(key);
            const { middleBand, upperBand, lowerBand } = calculateBollingerBands(key, 5, 2);
    
            const seriesData = changes.map((metric) => {
                // Enrichir les données de chaque métrique avec la couleur (rouge ou verte)
                const enrichedData = metric.changes.map((item) => ({
                    ...item,
                    fill: item.value < 0 ? "red" : "green",
                }));
                
                // Ajouter la série de la moyenne mobile
                let runningAvgData = metric.changes.map((item, index) => ({
                    index: item.index,  
                    value: getRunningAverage(metric.key, index),
                }));
                runningAvgData.shift();
    
                // Série pour la valeur actuelle
                const currentValueData = metric.changes.map((item, index) => ({
                    index: item.index,
                    value: parseFloat(data[item.index][metric.key]) || 0,  // Valeur actuelle
                }));
    
                // Série pour le gain ou la perte par rapport à la valeur précédente
                const gainLossData = metric.changes.map((item, index) => {
                    const previousValue = parseFloat(data[item.index - 1]?.[metric.key]) || 0;
                    const currentValue = parseFloat(data[item.index]?.[metric.key]) || 0;
                    const difference = currentValue - previousValue;
                    return {
                        index: item.index,
                        value: difference,  // Différence (gain ou perte)
                        fill: difference < 0 ? "red" : "green",  // Couleur selon gain/perte
                    };
                });
    
                return [
                    // Barres pour l'évolution (gain/perte)
                    {
                        type: "bar",
                        data: enrichedData,
                        xKey: "index",
                        yKey: "value",
                        yName: `${metric.key} - Évolution`,
                        fill: undefined,
                        fillOpacity: 1,
                        itemStyler: ({ datum }) => ({
                            fill: datum.fill,
                        }),
                    },
                    // Ligne pour la moyenne mobile
                    {
                        type: "line",  // La moyenne mobile sera tracée sous forme de ligne
                        data: runningAvgData,
                        xKey: "index",
                        yKey: "value",
                        title: `${metric.key} - Moyenne Mobile`,
                        stroke: "red",  // Couleur de la ligne moyenne mobile
                        strokeWidth: 3,  // Épaisseur de la ligne
                        lineDash :[5,5],
                        marker: { enabled: false },
                    },
                    // Barres pour la valeur actuelle
                    {
                        type: "bar",
                        data: currentValueData,
                        xKey: "index",
                        yKey: "value",
                        yName: `${metric.key} - Valeur Actuelle`,
                        fill: "blue",  // Couleur de la barre pour la valeur actuelle
                        fillOpacity: 0.7,  // Opacité de la barre
                    },
                    // Barres pour le gain ou la perte
                    {
                        type: "bar",
                        data: gainLossData,
                        xKey: "index",
                        yKey: "value",
                        yName: `${metric.key} - Gain/Perte`,
                        fill: undefined,
                        fillOpacity: 0.7,
                        itemStyler: ({ datum }) => ({
                            fill: datum.fill,  // Rouge pour la perte, vert pour le gain
                        }),
                    },
            // MACD
            {
                type: "line",
                data: macdLine,
                xKey: "index",
                yKey: "value",
                title: `${key} - MACD`,
                stroke: "blue",
                strokeWidth: 2,
            },
            {
                type: "line",
                data: signalLine,
                xKey: "index",
                yKey: "value",
                title: `${key} - Signal Line`,
                stroke: "orange",
                strokeWidth: 2,
            },
            // Bandes de Bollinger
            {
                type: "line",
                data: middleBand,
                xKey: "index",
                yKey: "value",
                title: `${key} - Bande Moyenne`,
                stroke: "green",
                strokeWidth: 2,
            },
            {
                type: "line",
                data: upperBand,
                xKey: "index",
                yKey: "value",
                title: `${key} - Bande Supérieure`,
                stroke: "red",
                strokeWidth: 2,
                lineDash: [5, 5],
            },
            {
                type: "line",
                data: lowerBand,
                xKey: "index",
                yKey: "value",
                title: `${key} - Bande Inférieure`,
                stroke: "red",
                strokeWidth: 2,
                lineDash: [5, 5],
            },
                ];
            }).flat();
    
            AgCharts.create({
                container: chartRef.current,
                title: { text: seriesData[0].title ? seriesData[0].title : seriesData[0].yName, fontSize: 18 },
                data: [],  // Pas besoin de données spécifiques ici
                series: seriesData,  // Séries avec les données, la moyenne mobile, la valeur actuelle, et le gain/perte
                navigator: {
                    enabled: true, // Permet à l'utilisateur de zoomer sur une plage spécifique
                },
                axes: [
                    {
                        type: 'number',
                        position: 'bottom',
                        title: { text: 'Index' },
                    },
                    {
                        type: 'number',
                        position: 'left',
                        title: { text: 'Évolution en %' },
                    },
                ],
            });
        }
    }, [typePanel, selectedKeys, data]);
    


    return (
        <div className="panel">
            <div className="quick-access">
                <button>Voir le graphique</button>
            </div>

            <div className="steps">
                <div className="subtitle">{title}</div>
                {data && data.length > 0 ? (
                    Object.keys(data[0]).map((key) => {
                        if (selectedKeys.has(key)) {
                            let content;
                            if (typePanel === type.TOTAL) {
                                const total = getTotal(key);
                                content = <div className="description"><strong>{key}:</strong> Le total est de <strong>{total.toFixed(2)}</strong>.</div>;
                            } else if (typePanel === type.EVOLUTION) {
                                const percentageChange = getPercentageChange(key);
                                content = <div className="description"><strong>{key}:</strong> L'évolution en pourcentage est de <strong>{percentageChange.toFixed(2)}%</strong> depuis la dernière donnée.</div>;
                            } else if (typePanel === type.COMPARE_PRED) {
                                const { avgDifference, avgPercentageDifference } = getPredictionDifference(key);
                                content = <div className="description"><strong>{key}:</strong> La différence moyenne entre les valeurs réelles et les prédictions est de <strong>{avgDifference.toFixed(2)}</strong>. Cela représente une différence de <strong>{avgPercentageDifference.toFixed(2)}%</strong> en moyenne.</div>;
                            } else if (typePanel === type.MAX_VALUE) {
                                const maxValue = getMaxValue(key);
                                content = <div className="description"><strong>{key}:</strong> La valeur maximale observée est de <strong>{maxValue.toFixed(2)}</strong>.</div>;
                            } else if (typePanel === type.MIN_VALUE) {
                                const minValue = getMinValue(key);
                                content = <div className="description"><strong>{key}:</strong> La valeur minimale observée est de <strong>{minValue.toFixed(2)}</strong>.</div>;
                            } else if (typePanel === type.MEDIAN_VALUE) {
                                const medianValue = getMedianValue(key);
                                content = <div className="description"><strong>{key}:</strong> La valeur médiane est de <strong>{medianValue.toFixed(2)}</strong>.</div>;
                            } else if (typePanel === type.EVOLUTION_LIST) {
                                content = (
                                    <div className="chart-container">
                                        <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>
                                        <Link to="/echowave/learn/indicators">
                                            <div className="helper">Comment comprendre les données ? apprendre en cliquant ici</div>
                                        </Link>
                                        <div style={{display: "flex"}}>
                                        <div class="helperCard">
                                            <div class="text">La moyenne mobile aide à voir les tendances d'une donnée en lissant les variations à court terme.</div>
                                        </div>
                                        <div class="helperCard">
                                            <div class="text">
                                            Les Bandes de Bollinger sont un outil d'analyse qui permet de mesurer la volatilité d'un ensemble de données, comme l'engagement sur les réseaux sociaux. Elles se composent de trois lignes : une moyenne mobile centrale et deux bandes (supérieure et inférieure) qui s'écartent en fonction de la volatilité.

                                            Lorsque les bandes se resserrent, cela indique une faible volatilité, souvent avant un changement significatif dans les données.
                                            Lorsque les bandes s'écartent, cela montre une forte volatilité, signalant une période d'activité intense.

                                            </div>
                                        </div>
                                        <div class="helperCard">
                                            <div class="text">
                                            Le MACD (Moving Average Convergence Divergence) est un indicateur qui mesure la force et la direction des tendances dans vos données, comme la croissance ou la baisse de l'engagement sur les réseaux sociaux. Il est composé de deux lignes principales :

                                            MACD Line : montre la différence entre deux moyennes mobiles exponentielles.

                                            Signal Line : une moyenne mobile de la ligne MACD, utilisée pour repérer les croisements.

                                            Lorsque la ligne MACD croise la signal line vers le haut, cela peut indiquer un début de tendance positive.

                                            Lorsque la MACD croise vers le bas, cela peut signaler une baisse ou un ralentissement.

                                            Le MACD est particulièrement utile pour détecter les changements de tendance et peut être combiné avec des Bandes de Bollinger pour confirmer la volatilité ou le RSI pour évaluer si une tendance est surachetée ou survendue.
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                );
                            }
                            return (
                                <div className="step" key={key}>
                                    {content}
                                </div>
                            );
                        }
                    })
                ) : (
                    <div className="no-data">Pas de données disponibles</div>
                )}
            </div>
        </div>
    );
};

export default AnalyzePanel;
