import React from 'react';
import './LoadingSpinner.css';

function LoadingSpinner() {
  return (            
  <div style={{ position: 'relative'}}>
    <div className="loading-overlay">
      <div className="spinner"></div>
    </div>
    </div>
  );
}

export default LoadingSpinner;
