import React from "react";
import "./term of service.css";
import Footer from "../../EchowaveLandingPage/components/Footer";
import Header from "../../EchowaveLandingPage/components/Header";

const TermOfService = () =>
    {
        return (
            <section>
                <Header/>
                <div className="privacy-policy-container">
    <header>
        <h1>Conditions Générales d'Utilisation</h1>
        <p><strong>Date d'entrée en vigueur : 01/10/2024</strong></p>
    </header>
    
    <div className="terms-container">
        <h2>1. Acceptation des Conditions</h2>
        <p>En accédant ou en utilisant nos services, vous confirmez que vous avez lu, compris et accepté ces conditions. Si vous n'acceptez pas une partie de ces conditions, vous devez ne pas utiliser nos services.</p>

        <h2>2. Services fournis</h2>
        <p>Pulse Software propose des services de développement d'applications et des fonctionnalités connexes via notre application et notre site Web. Nous pouvons également fournir des mises à jour, des améliorations ou de nouvelles fonctionnalités dans le cadre de nos services.</p>

        <h2>3. Comptes Utilisateurs</h2>
        <p>Pour accéder à certaines fonctionnalités de nos services, vous devrez peut-être créer un compte. Vous acceptez de fournir des informations exactes, complètes et à jour lors du processus d'inscription et de maintenir vos informations de compte à jour. Vous êtes responsable du maintien de la confidentialité de vos identifiants de compte et de toutes les activités qui se produisent sous votre compte.</p>

        <h2>4. Responsabilités de l'utilisateur</h2>
        <p>Vous acceptez d'utiliser nos services conformément à toutes les lois et réglementations applicables. Vous êtes interdit de :</p>
        <ul>
            <li>Utiliser nos services de manière non autorisée.</li>
            <li>Transmettre tout contenu nuisible ou illégal.</li>
            <li>Tenter d'interférer avec la sécurité ou l'intégrité de nos services.</li>
            <li>Collecter des informations personnelles auprès d'autres utilisateurs sans leur consentement.</li>
        </ul>

        <h2>5. Conditions de paiement</h2>
        <p>Si vous achetez des services ou des abonnements, vous acceptez de payer tous les frais applicables. Nous nous réservons le droit de modifier nos tarifs à tout moment, mais nous vous en informerons à l'avance.</p>

        <h2>6. Droits de propriété intellectuelle</h2>
        <p>Tous les contenus, marques commerciales et autres droits de propriété intellectuelle associés à nos services sont la propriété de Pulse Software ou sont sous licence de Pulse Software. Vous ne pouvez pas reproduire, distribuer, modifier ou créer des œuvres dérivées de tout contenu sans notre consentement écrit préalable.</p>

        <h2>7. Résiliation</h2>
        <p>Nous pouvons suspendre ou résilier votre accès à nos services à tout moment, avec ou sans préavis, si nous estimons que vous avez violé ces conditions ou avez adopté un comportement susceptible de nuire à nos services ou à d'autres utilisateurs.</p>

        <h2>8. Limitation de responsabilité</h2>
        <p>Dans toute la mesure permise par la loi, Pulse Software ne sera pas responsable des dommages indirects, accessoires, spéciaux, consécutifs ou punitifs, y compris la perte de profits, résultant de votre utilisation ou de votre incapacité à utiliser nos services.</p>

        <h2>9. Indemnisation</h2>
        <p>Vous acceptez d'indemniser et de dégager de toute responsabilité Pulse Software, ses affiliés, ainsi que leurs dirigeants, directeurs, employés et agents, de toute réclamation, perte, responsabilité, dommage, coût ou dépense résultant de ou lié à votre utilisation de nos services ou à la violation de ces conditions.</p>

        <h2>10. Modifications des Conditions</h2>
        <p>Nous nous réservons le droit de modifier ces conditions à tout moment. Toute modification sera publiée sur cette page et nous vous informerons par e-mail ou par d'autres moyens de communication si nécessaire. Votre utilisation continue de nos services après toute modification constitue votre acceptation des conditions révisées.</p>
        
        <h2>11. Contactez-nous</h2>
        <p>Si vous avez des questions concernant ces conditions, veuillez nous contacter à l'adresse suivante :</p>
        <p><strong>Pulse Software</strong><br />
        Email : dotsoft.pro@gmail.com<br /></p>
    </div>
    </div>
    <Footer/>
</section>
        );
    }
    

export default TermOfService;
