import React from "react";
import { useState, useEffect} from "react";
import '../CircularGraphHours/CircularGraphHours.css';
import { GetUserCompanyData } from "../../../GatherUserInformationDB/GatherDatabaseUser";
import { GenerateCellStyle } from "./OvertimeAnalyseTable";
import CreateTable from "./TableClass";
import ChartModule from "../../chartMaker/ChartModule";
import { AgChart } from 'ag-charts-community';


const GeneraleDataTable = () =>
{        const [rowTableGeneral, setRowTableGenerale] = useState([]);
    const [colTableGeneral, setColTableGeneral] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [globalData, setGlobalData] = useState([]);
    const [graphLabel, setGraphLabel] = useState([
'instagram_impressions',
"instagram_reach",
"instagram_total_interactions",
"instagram_accounts_engaged",
"instagram_likes",
"instagram_comments",
"instagram_shares",
"instagram_replies",
"instagram_profile_views",
"instagram_profile_links_taps",
    ]);

    const GetDatas = async() =>
    {    
        let allRow = [];
        let finalDataCol = [];
        let graphDataFormatted = [];
        
        finalDataCol.push({field: "date", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: 'instagram_impressions', cellStyle: GenerateCellStyle()});

        finalDataCol.push({field: "instagram_reach", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_total_interactions", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_accounts_engaged", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_likes", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_comments", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_shares", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_replies", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_profile_views", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "instagram_profile_links_taps", cellStyle: GenerateCellStyle()})

        const compData = await GetUserCompanyData();
        setGlobalData(compData[0])
        Object.entries(compData[0]).forEach(([key, value]) => {
            let finalDataRow = {}; 
            if(!isNaN(new Date(key).getTime()))
            {
                finalDataRow["date"] = key; 
            
                Object.entries(value).forEach(([subKey, subValue]) => {
                    finalDataRow[subKey] = subValue;
                });
                allRow.push(finalDataRow);
                graphDataFormatted.push(finalDataRow);
            }
        });
        allRow.sort((a, b) => 
            {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA - dateB;
            });
            graphDataFormatted.sort((a, b) => 
                {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateA - dateB;
                });
        setColTableGeneral(finalDataCol);
        setRowTableGenerale(allRow);
        setGraphData(graphDataFormatted);
    }

    useEffect(() => 
    {
        GetDatas();
    }, []);
    return (
        <div>
            <h1>General data</h1>
            <CreateTable col= {colTableGeneral} row={rowTableGeneral} allDatas={globalData}/>
            <ChartModule
                chartTitle="Instagram General Data Overview"
                dataSets={[graphData]}    // Use the fetched graph data
                chartType="line"
                xField="date"
                yFields={graphLabel}      // Label fields for Y-axis
                theme="ag-pastel"
                height={500}
            />
    </div>
    );
}

export default GeneraleDataTable;