import React, { useEffect, useState } from "react";
import { Line, Bar, Scatter } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, BarElement, PointElement, Tooltip, Legend, Title } from "chart.js";
import SocialMediaAnalysis from "./chartAnalyzer/chartAnalyzer";
import { GetCompanyId } from "../../GatherUserInformationDB/GatherDatabaseUser";
import { hostLink } from "../../DatabaseCommunication/GetDataFromUser";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, LineElement, BarElement, PointElement, Tooltip, Legend, Title);

const ChartModule = ({ chartTitle, dataSets, xField, yFields, height = 500, hasPred = true }) => {
    const [chartType, setChartType] = useState("line"); // State for chart type
    const [chartData, setChartData] = useState(null); // Chart.js-compatible data
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => 
            {
                if (!dataSets || dataSets.length === 0) return;
            
                try 
                {
                    // Fetch company ID
                    let compIdResponse = await GetCompanyId();
                    let compId = await compIdResponse.json();
                    compId = compId["id"];
            
                    // Fetch prediction data
                    let predResponse = await fetch(`${hostLink}/GetPredictionData`, 
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ id: compId }),
                    });
            
                    let pred = await predResponse.json();
                    pred = pred["data"].map(JSON.parse);
                    pred = pred[0];
            
                    let combinedData = mergeDataSets(dataSets, xField);
                    const predField = new Set();
            
                    // Récupération des dates existantes
                    const existingDates = new Set(combinedData.map((dataRow) => dataRow["date"]));
            
                    // Mise à jour des données combinées
                    combinedData = combinedData.map((dataRow) => {
                        const rowWithPrediction = { ...dataRow };
            
                        yFields.forEach((column) => {
                            const predictionField = `${column}_prediction`;
                            predField.add(predictionField); // Ajoute le champ au Set
            
                            if (pred[dataRow["date"]]) {
                                // Récupérer la valeur de prédiction ou 0 si inexistante
                                rowWithPrediction[predictionField] =
                                    pred[dataRow["date"]][predictionField] ?? 0;
                            } else {
                                rowWithPrediction[predictionField] = 0;
                            }
                        });
            
                        return rowWithPrediction;
                    });
            
                    // Ajout des lignes restantes de prédictions
                    Object.keys(pred).forEach((date) => 
                    {
                        if (!existingDates.has(date)) 
                        {
                            const newRow = { date };
            
                            yFields.forEach((column) => 
                            {
                                const predictionField = `${column}_prediction`;
                                predField.add(predictionField);
            
                                // Initialiser les champs
                                newRow[predictionField] = pred[date][predictionField] ?? 0;
                                newRow[column] = 0; // Valeurs non prédiction à 0
                            });
            
                            combinedData.push(newRow);
                        }
                    });
            
                    // Fusionne les nouveaux champs avec les anciens sans doublons
                    yFields = Array.from(new Set([...yFields, ...predField]));
            
                    console.log("Combined Data:", combinedData);
            
                    // Prepare data for Chart.js
                    const datasets = yFields.map((field) => 
                    {
                        const isPrediction = field.endsWith("_prediction");
            
                        return {
                            label: isPrediction ? `${field} (Prediction)` : field,
                            data: combinedData.map((item) => item[field] || 0),
                            borderColor: isPrediction ? "red" : getRandomColor(),
                            borderDash: isPrediction ? [5, 5] : [],
                            backgroundColor: isPrediction ? "rgba(255, 0, 0, 0.3)" : getRandomColor(0.3),
                            tension: 0.4, // Smooth lines
                            fill: false, // No fill for lines
                            type: chartType === "area" ? "line" : chartType,
                        };
                    });
            
                    setChartData({
                        labels: combinedData.map((item) => item[xField]),
                        datasets: datasets,
                    });
            
                } 
                catch (error) 
                {
                    console.error("Error fetching or processing data:", error);
                }
            };
            
            

        fetchData();
    }, [dataSets, chartType, xField, yFields, hasPred]);

    const getRandomColor = (alpha = 1) => {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    const mergeDataSets = (dataSets, xField) => {
        let mergedData = [];
        dataSets.forEach((dataSet) => {
            dataSet.forEach((item) => {
                let existingItem = mergedData.find((data) => data[xField] === item[xField]);
                if (existingItem) {
                    Object.assign(existingItem, item);
                } else {
                    mergedData.push({ ...item });
                }
            });
        });
        return mergedData;
    };

    const handleChartTypeChange = (event) => {
        setChartType(event.target.value);
    };

    const renderChart = () => {
        if (!chartData) return null;

        switch (chartType) {
            case "line":
                return <Line data={chartData} />;
            case "bar":
                return <Bar data={chartData} />;
            default:
                return <Scatter data={chartData} />;
        }
    };

    return (
        <div style={{ width: "100%"}}>
            <hr />
            <div className="tableSwitcher">
                <button onClick={() => setIsOpen(true)}>Open Detailed Analysis</button>
                <label htmlFor="chartType" style={{ marginRight: "10px" }}>
                    Select Chart Type:
                </label>
                <select
                    id="chartType"
                    value={chartType}
                    onChange={handleChartTypeChange}
                    style={{ padding: "5px", fontSize: "14px" }}
                >
                    <option value="line">Line</option>
                    <option value="bar">Bar</option>
                    <option value="area">Area</option>
                </select>
            </div>
            <div style={{ height: `100%`, width: "100%" }}>
                {chartData ? renderChart() : <p>Loading chart...</p>}
            </div>
            <SocialMediaAnalysis data={dataSets[0]} isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
    );
};

export default ChartModule;
