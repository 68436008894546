import React, { useState, useEffect } from 'react';
import './CircularGraphHours.css';
import CircularGraph from './GraphGenerator';
import { GetUserCompanyData } from '../../../GatherUserInformationDB/GatherDatabaseUser';
import LoadingSpinner from '../../../loading/LoadingSpinner.js';

function CircularGraphHours() {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [weeklyData, setWeeklyData] = useState([]);
    const [sortedDays, setSortedDays] = useState([]);
    const [day, setDay] = useState(0);

    const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

    const handleDayChange = (event) => {
        setDay(parseInt(event.target.value)); // Convertit la valeur en entier
    };

    const GetDatas = async () => {
        const compData = await GetUserCompanyData();
        const indexValue = compData.length - 1;
        const daysDataInfluence = compData[indexValue].instagram_per_hour_follow;

        // Analyse hebdomadaire
        const weeklyData = daysDataInfluence.map((dayData, dayIndex) => {
            const bestHour = dayData.data_points.reduce(
                (best, current, index) => (current.value > best.value ? { hour: index, value: current.value } : best),
                { hour: 0, value: 0 }
            );
            return { day: daysOfWeek[dayIndex], ...bestHour };
        });

        // Trie les jours par la valeur maximale
        const sortedData = [...weeklyData].sort((a, b) => b.value - a.value);

        // Données pour le jour sélectionné
        const currentDayData = daysDataInfluence[day].data_points.map((hour, index) => ({
            label: index,
            value: hour.value,
        }));

        setWeeklyData(weeklyData);
        setSortedDays(sortedData);
        setData(currentDayData);
        setIsLoading(false);
    };

    useEffect(() => {
        GetDatas();
    }, [day]);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div>
            <h1 id="statsStatisticsName">Statistiques Hebdomadaires</h1>
            <div className="containerNetwork">
                <div className="stats-label">Analyse complète des meilleurs horaires pour publier :</div>
                <hr className="stats-separator" />
                <div className="card">
                    {sortedDays.map((dayInfo, index) => (
                        <p key={index} className="day-analysis">
                            <span className="day-rank">#{index + 1} - </span>
                            <span className="day-name">{dayInfo.day}</span> : 
                            Publiez à <span className="day-hour">{dayInfo.hour}h </span> 
                            pour un maximum de <span className="day-value">{dayInfo.value}</span> interactions.
                        </p>
                    ))}
                </div>
                <hr className="stats-separator" />
                <div className="stats-label">Graphique des interactions pour {daysOfWeek[day]} :</div>
                <div className="stats-chart-and-options-container">
                    <div className="stats-chart-options-card" id="stats-chart-options-card">
                        <div className="stats-one-option-block">
                            <div className="stats-checkbox-container">
                                <div>Choisissez un jour :</div>
                            </div>
                            <select value={day} onChange={handleDayChange} className="day-selector">
                                {daysOfWeek.map((dayName, index) => (
                                    <option key={index} value={index}>
                                        {dayName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <hr />
                    </div>
                    <CircularGraph datas={data} />
                </div>
            </div>
        </div>
    );
}

export default CircularGraphHours;
