import { hostLink } from "./GetDataFromUser";

async function createCustomer(email) 
{
    console.log(email);
    try 
    {
        const response = await fetch(hostLink+'/createCustomerStripe', 
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email }),
        });

        const result = await response.json();
        console.log("result is " + result);

        // Diverse return handling based on the message in the response
        if (response.ok) 
        {
            if (result.message === 'Customer with this email already exists') 
            {
                return { status: 'exists', customerId: result.customer_id, message: result.message };
            } 
            else if (result.message === 'Customer created successfully') 
            {
                return { status: 'created', customerId: result.customer_id, message: result.message };
            }
        } 
        else 
        {
            return { status: 'error', message: result.error || 'Something went wrong' };
        }
    } 
    catch (error) 
    {
        return { status: 'error', message: error.message || 'Error creating customer' };
    }
}

async function SubScribeToEchowave(_custid) 
{
    try 
    {
        // Make a POST request to the Flask backend
        const response = await fetch(hostLink + `/GetUserSubToEcho`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({"customer_id" :  _custid }),
        });

        // Await the result of response.json() to get the actual JSON data
        const result = await response.json();

        console.log("The returned URL is: " + result.url);

        if (result.url) 
        {
            console.log("lets finally return it !")
            // Return the Stripe Checkout session URL
            return result.url;
        } 
        else 
        {
            console.error('Error:', result.error);
            return `Error: ${result.error}`;
        }
    } 
    catch (error) 
    {
        console.error('Error:', error.message);
        return `Error: ${error.message}`;
    }
}



export {createCustomer, SubScribeToEchowave};