import { hostLink } from "../DatabaseCommunication/GetDataFromUser";
import { CreateOneMsgBox } from "./CreateOneMsgBox";

// Function to show the chat popup
export function showChatPopup(username, {selectedUserId, currentUserId, actualUserName, userstalkingtoo,setlatestMessageTimestamp }) {
    const chatPopup = document.getElementById('chat-popup');
    document.getElementById('chat-username').innerText = username;
    chatPopup.classList.remove('hidden');
    chatPopup.classList.add('visible');
    chatPopup.style.width = "400px";
    chatPopup.style.height = "500px";
    chatPopup.style.top = "70%";
    chatPopup.style.left = "85%";
    console.log('showing good vibes');
    loadMessages(false, [], {selectedUserId,currentUserId, setlatestMessageTimestamp});  // Load messages when the popup is shown
}


export function loadMessages(alreadyQuery = false, dataMsgs = [], {selectedUserId,currentUserId, setlatestMessageTimestamp}) 
{
    console.log(selectedUserId);
    if (!alreadyQuery)
    {
        const query = `
        SELECT messages.*, sender.username AS sender_username, receiver.username AS receiver_username
        FROM messages
        JOIN \`user\` sender ON messages.sender_id = sender.id
        JOIN \`user\` receiver ON messages.receiver_id = receiver.id
        WHERE sender.id = ${selectedUserId} OR receiver.id = ${selectedUserId}
        ORDER BY timestamps ASC
      `;
      fetch(hostLink + '/SpecialQueryGetnoVar', 
        {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json' // Set the content type to JSON
        },
        body: JSON.stringify({ 
            query: query
        })
        })
        .then(response => response.json())
        .then(messages => {
            let chatMessages = document.getElementById('chat-messages');
            chatMessages.innerHTML = '';  // Clear existing messages
                console.log(selectedUserId, " ", currentUserId);
                console.log(messages);
            //latestMessageTimestamp = new Date(messages.data[messages.data.length -1][4]);
            messages.data.forEach((msg, index) => {
              CreateOneMsgBox(msg, messages, index);
            });
            console.log();
            setlatestMessageTimestamp(new Date());
            // Scroll to the bottom
            chatMessages.scrollTop = chatMessages.scrollHeight;
                
        })
        .catch(error => {
            console.error('Error loading messages:', error);
        });
      }
}

