import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './sideBar.css';
import '../../App.css';

const SideMenu = () => {
    const [menuPosition, setMenuPosition] = useState('-200px'); // Initial position of the menu

    // Function to determine if the menu should open or close
    const shouldOpenMenu = (mouseX, menuLeft) => {
        return mouseX <= 10 || (menuLeft === '0' && mouseX <= 300);
    };

    // Update the position of the menu based on mouse position


    // Set up event listener for mouse movements when component mounts
    useEffect(() => {
      const updateMenuPosition = (mouseX) => {
        if (shouldOpenMenu(mouseX, menuPosition)) {
            setMenuPosition('0'); // Open menu
        } else {
            setMenuPosition('-200px'); // Close menu
        }
    };
        const handleMouseMove = (e) => {
            updateMenuPosition(e.clientX);
        };
        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove); // Cleanup listener on component unmount
        };
    }, [menuPosition]); // Re-run effect if menuPosition changes

    return (
        <div id="menu" style={{ left: menuPosition, transition: 'left 0.3s ease-in-out' }}>
            <h1>Menu</h1>
            <Link className="menu-button" to="/echowave/profil" style={{ textDecoration: 'none' }}>Compte</Link>
            <Link className="menu-button" to="/echowave/Network" style={{ textDecoration: 'none' }}>Analyser</Link>
            {/* <Link className="menu-button" to="/echowave/companyFeed" style={{ textDecoration: 'none' }}>Company Feed</Link>             */}
            <Link className="menu-button" to="/" style={{ textDecoration: 'none' }}>Retour menu</Link>
            {/* <a className="menu-button" href="https://pulsesoftwareapp.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>Actua</a> */}
        </div>
    );
};

export default SideMenu;
