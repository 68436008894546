import React, { useState } from 'react';
import './EventList.css'; // Assuming you create a separate CSS file for styles

const EventList = ({ initialEvents }) => {
  // Use useState to manage the events state
  const [events, setEvents] = useState(initialEvents);
  const today = new Date();
  let addTodaySeparator = false;

  // Function to handle the inf value change
  const changeEventInf = (index, newInf) => {
    const updatedEvents = [...events]; // Create a copy of the events
    updatedEvents[index].inf = newInf; // Update the specific event's inf value
    setEvents(updatedEvents); // Update the state
  };

  return (
    <div id="projectFeedEvents">
      <h3 className="events-title">Events: ({events.length})</h3>
      {events.map((event, index) => {
        const isFutureEvent = new Date(event.date) >= today;

        if (isFutureEvent && !addTodaySeparator) {
          addTodaySeparator = true;
          return (
            <React.Fragment key={`today-${index}`}>
              <h3 id="todayDate">
                {today.getDate()} {today.toLocaleString('en-US', { month: 'long' })} {today.getFullYear()}
              </h3>
              <hr className="separator" />
              <EventCard event={event} index={index} onInfChange={changeEventInf} />
            </React.Fragment>
          );
        }

        return <EventCard key={index} event={event} index={index} onInfChange={changeEventInf} />;
      })}
    </div>
  );
};

const EventCard = ({ event, index, onInfChange }) => {
  // Check if the event is today
  const isToday = () => {
    const eventDate = new Date(event.date);
    const today = new Date();
    return (
      eventDate.getFullYear() === today.getFullYear() &&
      eventDate.getMonth() === today.getMonth() &&
      eventDate.getDate() === today.getDate()
    );
  };

  return (
    <div
      className={`containerEvent ${isToday() ? 'highlighted-event' : ''} ${event.inf}`}
      style={{
        position: 'relative', // Add relative positioning to container
      }}
    >
      {/* Buttons container */}
      <div className="buttonContainer">
        <div className="smallButton" style={{ color: 'orange' }} onClick={() => onInfChange(index, 'warn')}>
          Make warn.
        </div>
        <div className="smallButton" style={{ color: 'red' }} onClick={() => onInfChange(index, 'fail')}>
          Make fail.
        </div>
        <div className="smallButton" style={{ color: 'green' }} onClick={() => onInfChange(index, 'success')}>
          Make success.
        </div>
        <div className="smallButton" style={{ color: 'black' }} onClick={() => onInfChange(index, 'none')}>
          remove indicator.
        </div>
      </div>

      <hr className="separator" />
      <div className="informationEvent">
        <h3 className="event-title">{event.title}</h3>
        <hr className="separator" />
        <div className="event-date">{new Date(event.date).toLocaleDateString()}</div>
      </div>
      <hr className="separator" />
      <div className="event-description">{event.desc}</div>
      <hr className="separator" />
    </div>
  );
};

const GetBackgroundColor = (inf) => {
  switch (inf) {
    case 'warn':
      return 'rgb(255, 255, 204)'; // Light yellow
    case 'fail':
      return 'rgb(255, 204, 204)'; // Light red
    case 'success':
      return 'rgb(204, 255, 204)'; // Light green
    case 'none':
      return 'rgb(255, 255, 255)'; // White
    default:
      return 'rgb(250, 255, 255)'; // Default to white if no match
  }
};

export default EventList;
