import React, { useState } from "react";
import "./UserConnecting.css";
import PasswordRecoveryModal from "../PasswordRecoverModal/PasswordRecoverModal";
import CreateAccountModal from "../AccountCreation/AccountCreation";
import Cookies from "js-cookie";
import { isCookieCredentialStillValid } from "../../DatabaseCommunication/GetDataFromUser";
import { useNavigate } from "react-router-dom";

const UserConnecting = () => 
{
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenCreatingAcc, setIsModalOpenCreatingAcc] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const openModal = () => 
    {
        setIsModalOpen(true);
    };

    const closeModal = () => 
    {
        setIsModalOpen(false);
    };

    const togglePasswordVisibility = () => 
    {
        setPasswordVisible(!passwordVisible);
    };

    const ConnectUser = async () => 
    {
        const username = document.getElementById("username").value;
        const password = document.getElementById("password").value;

        if (username && password) 
        {
            Cookies.set('authPulseUsername', username, { expires: 7 });
            Cookies.set('authPulsePassword', password, { expires: 7 });

            if (await isCookieCredentialStillValid()) 
            {
                navigate("/Echowave");
            } 
            else 
            {
                document.getElementById("errorConn").textContent = "Erreur d'identifiants";
            }
        }
    };

    const handleKeyPress = (event) => 
    {
        //if (event.key === "Enter") 
        //{
        //    ConnectUser();
        //}
    };

    return (
        <section className="connectionCard">
            <div className="headerText">Connectez-vous avec votre compte Pulse</div>
            <div className="connectionLabel">
                <label htmlFor="username">Nom d'utilisateur</label>
                <input 
                    type="text" 
                    id="username" 
                    className="inputField" 
                    placeholder="Entrez votre nom d'utilisateur" 
                    onKeyPress={() => handleKeyPress()}
                />
            </div>
            <div className="connectionLabel">
                <label htmlFor="password">Mot de passe</label>
                <div className="passwordFieldWrapper">
                    <input 
                        type={passwordVisible ? "text" : "password"} 
                        id="password" 
                        className="inputField" 
                        placeholder="Entrez votre mot de passe" 
                        onKeyPress={() => handleKeyPress()}
                    />
                    <button 
                        type="button" 
                        className="togglePasswordVisibility" 
                        onClick={togglePasswordVisibility}
                        aria-label={passwordVisible ? "Masquer le mot de passe" : "Afficher le mot de passe"}
                    >
                        {passwordVisible ? "👁️‍🗨️" : "👁️"}
                    </button>
                </div>
            </div>
            <div className="connectionLabel">
                <button className="directLink" onClick={openModal}>Récupérer le mot de passe</button>
                <PasswordRecoveryModal isOpen={isModalOpen} closeModal={closeModal} />
                <button className="directLink" onClick={() => setIsModalOpenCreatingAcc(true)}>Créer un compte</button>
                <CreateAccountModal isOpen={isModalOpenCreatingAcc} closeModal={() => setIsModalOpenCreatingAcc(false)} />
                <div id="errorConn" style={{ color: "red" }}></div>
            </div>
            <button className="connectButton" onClick={ConnectUser}>Se connecter</button>
        </section>
    );
};

export default UserConnecting;
