import React, { useState } from "react";
import './TaskEvent.css';
import './EventList.css';

const TaskViewer = ({ tasks }) => 
{
    const [taskList, setTaskList] = useState(tasks);

    const toggleTaskStatus = (index) => 
    {
        setTaskList(prevTasks => 
            prevTasks.map((task, i) => 
                i === index ? { ...task, isDone: !task.isDone } : task
            )
        );
    };

    return (
        <div id="projectFeedEvents">
            <h3 className="events-title">Tasks: ({taskList.length})</h3>
            {taskList.map((value, index) => (
                <RenderTask key={index} task={value} onClick={() => toggleTaskStatus(index)} />
            ))}
        </div>
    );
};


const RenderTask = ({ task, onClick }) => 
    {
        const [subtasks, setSubtasks] = useState(task.addInfo);
        const [isModalOpen, setModalOpen] = useState(false);
        const [subtaskData, setSubtaskData] = useState({
            date: new Date().toISOString().split("T")[0], // Today's date in YYYY-MM-DD format
            data: ""
        });
    
        const openModal = () => 
        {
            setModalOpen(true);
        };
    
        const closeModal = () => 
        {
            setModalOpen(false);
            setSubtaskData({
                date: new Date().toISOString().split("T")[0],
                data: ""
            });
        };
    
        const handleInputChange = (e) => 
        {
            const { name, value } = e.target;
            setSubtaskData({ ...subtaskData, [name]: value });
        };
    
        const onAddSubtask = () => 
        {
            setSubtasks([...subtasks, [subtaskData.date, subtaskData.data]]);
            closeModal();
        };
    
        return (
            <div
                className={`containerEvent ${task.isDone ? 'done-card' : 'not-done-card'}`}
                onClick={onClick}
            >
                <hr className="separator" />
                <div className="informationEvent">
                    <h3 className="event-title">{task.title}</h3>
                    <hr className="separator" />
                    <div className="event-description">{task.desc}</div>
                    <hr className="separator" />
                    {subtasks.map((element, index) => (
                        <GenerateSubDataTask key={index} index={index} element={element} />
                    ))}
    
                    <div className="add-subtask" onClick={openModal}>+</div>
                </div>
    
                {isModalOpen && (
                    <div className="modal-overlayTask">
                        <div className="modal-contentTask">
                            <h3>Add New Subtask</h3>
                            <label>
                                Date:
                                <input 
                                    type="date" 
                                    name="date" 
                                    value={subtaskData.date} 
                                    onChange={handleInputChange} 
                                />
                            </label>
                            <label>
                                Data:
                                <input 
                                    type="text" 
                                    name="data" 
                                    value={subtaskData.data} 
                                    onChange={handleInputChange} 
                                    placeholder="Enter subtask details" 
                                />
                            </label>
                            <div className="modal-buttonsTask">
                                <button onClick={onAddSubtask}>Add</button>
                                <button onClick={closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };
    
    function GenerateSubDataTask({ index, element }) 
    {
        return (
            <div key={index} className="info-section">
                <hr className="separator" />
                <div className="info-date">{element[0]}</div> {/* date */}
                <div className="info-data">{element[1]}</div> {/* data */}
                <hr className="separator" />
            </div>
        );
    }
export default TaskViewer;
