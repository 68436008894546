import Cookies from "js-cookie";
var currentUserId;
var actualUserName = ""; 
var userstalkingtoo = "";
var chatMessages;

export function CreateOneMsgBox(msg, messages, index) {    
    currentUserId = Cookies.get("userId");
    console.log(msg[1] , "+", currentUserId, "+",msg[1] == currentUserId);
    actualUserName = msg[6];
    userstalkingtoo = msg[6];
    chatMessages = document.getElementById('chat-messages');
    // Create message container
    const messageElement = document.createElement('div');
    const content = document.createElement('div');
    content.className = 'text';
    content.innerHTML = makeLinksClickable(removeImageLinks(msg[3]));

    var image = FindAllImageLinks(msg[3]);

    // Determine if it's sent or received
    messageElement.className = msg[1] == currentUserId ? 'message sent' : 'message received';
    const username = msg[6];

    // Add data attributes for sender and time
    messageElement.setAttribute('data-sender', username); // Assuming you have sender's name
    messageElement.setAttribute('data-time', new Date(msg[4]).toLocaleString()); // Assuming msg.timestamp contains the time

    if (IsSameUserOrDay(messages.data, index)) {
        messageElement.style.borderRadius = msg[1] == currentUserId ? '5px 5px 5px 20px' : '5px 5px 20px 5px';
        if (IsSameUserOrDay(messages.data, index, false)) {
            messageElement.style.borderRadius = msg[1] == currentUserId ? '5px 5px 5px 5px' : '5px 5px 5px 5px';
        }

    }

    else {
        if (IsSameUserOrDay(messages.data, index, false)) {
            messageElement.style.borderRadius = msg[1] == currentUserId ? '20px 5px 5px 5px' : '5px 20px 5px 5px';
        }
    }

    // Append the content
    if (index > 0) {
        CreateDateSeparator(messages.data, index);
        CreateUsernameOnTopMsg(messages.data, index);

    }
    messageElement.appendChild(content);

    // Handle file (if present in msg[5])
    const imgContainer = document.createElement('div');
    imgContainer.id = 'imgContainer'; // Assign an id for styling

    image.forEach(link => {
        const fileElement = document.createElement('div');
        fileElement.className = 'file';
        fileElement.style.width = '150px'; // Fixed width
        fileElement.style.height = '150px'; // Fixed height
        fileElement.style.overflow = 'hidden'; // Hide overflowed content

        const fileUrl = link;

        // Check if it's an image or another file type
        if ('/(https?:\/\/.*\.(?:png|jpg|jpeg|gif))/i') {
            const img = document.createElement('img');
            img.src = fileUrl;
            img.alt = 'Message image';
            img.className = 'message-image';
            imgContainer.appendChild(img);
        }

        else {
            const fileLink = document.createElement('a');
            fileLink.href = fileUrl;
            fileLink.textContent = 'Download File';
            fileLink.download = '';
            fileLink.className = 'message-file';
            imgContainer.appendChild(fileLink);
        }
    });

    messageElement.appendChild(imgContainer);

    // Create a hidden div for message details (sender and timestamp)
    const details = document.createElement('div');
    details.className = 'message-details';
    messageElement.appendChild(details);

    // Add click event to reveal sender and time
    messageElement.addEventListener('click', function () {
        if (details.innerHTML === "") {
            details.innerHTML = `${username} | ${new Date(msg[4]).toLocaleString()}`;
            details.style.display = "block";
        } else {
            details.innerHTML = "";
            details.style.display = "none";
        }
    });

    chatMessages.appendChild(messageElement);
}


function FindAllImageLinks(str)
{
  const linkPattern = /(https?:\/\/[^\s]+)/gi; // Matches all URLs
  const matches = [...str.matchAll(linkPattern)]; // Convert the iterable into an array
  
  if(matches.length > 0)
  {
      return matches.map(match => match[0]); // Return an array of found links
  }
  
  return [];
}

function removeImageLinks(inputString) {
  // Regular expression to match image URLs (png, jpeg, jpg, gif, etc.)
  const imageUrlPattern = /https?:\/\/[^\s/$.?#].[^\s]*(?:\.png|\.jpg|\.jpeg|\.gif|\.bmp|\.tiff|\.svg)/gi;

  // Replace matched URLs with an empty string
  return inputString.replace(imageUrlPattern, '');
}

function makeLinksClickable(text) {
  const urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/g; // Regex to match URLs
  return text.replace(urlPattern, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`; // Create clickable link
  });
}


function CreateUsernameOnTopMsg(messages, index) 
{
  const isActualUser = messages[index][1] == currentUserId;
  const latestUsername = messages[index][6];
  if (!IsSameUserOrDay(messages, index)) 
    {
    const details = document.createElement('div');
    details.className = 'message-details';
    details.style.display = "block";   
    details.style.textAlign = isActualUser ? 'right' :'left';                                                                                         
    details.textContent = latestUsername;
    chatMessages.appendChild(details);
  }

}

function IsSameUserOrDay(messages, index, less = true) 
{
  // Ensure the index is within bounds
  if ((index > 0 && less) || (index < messages.length - 1 && !less)) 
  {
    //console.log(messages[index]);
    const isActualUser = (messages[index])[1] == currentUserId;
    
    // Adjust the index safely
    const adjustedIndex = less ? index - 1 : index + 1;
    
    // Ensure adjustedIndex is valid
    if (adjustedIndex < 0 || adjustedIndex >= messages.length) 
    {
      return false;
    }

    const oldestUsername = (messages[adjustedIndex])[6];
    const latestUsername = (messages[index])[6];
    
    const oldestDate = new Date((messages[adjustedIndex])[4]);
    const latestDate = new Date((messages[index])[4]);

    return oldestUsername == latestUsername && oldestDate.getDate() == latestDate.getDate();
  }
  else 
  {
    return false;
  }
}


function CreateDateSeparator(messages, index) {
  const oldestDate = new Date(messages[index - 1][4]);
  const latestDate = new Date(messages[index][4]);

  //check if the date is the same for the last msg and actual
  if (oldestDate.getDate() != latestDate.getDate()) {
    //if not the same, we can add a separator (because not the same day)
    const details = document.createElement('div');
    details.className = 'message-details';
    details.style.display = "block";
    details.style.marginTop = "10px";
    details.textContent = latestDate.toDateString();

    chatMessages.appendChild(details);
    chatMessages.appendChild(document.createElement('hr'));
  }

}