import React, { useState } from 'react';
import './PatchnoteModal.css';

const PatchnoteModal = () => 
{
    const [isOpen, setIsOpen] = useState(true);
    if (!isOpen) return null;

    // Données d'exemple pour les notes de mise à jour
    const patchNotes = [
        { version: 'v0.2.0', description: 'Amélioration de la connexion à instagram (passage par Facebook !) nouveaux designs pour les graphiques et analyse de donnée. Système d\'analyse détaillé (indicateurs mathématiques). Système de prediction des évolutions des données.'},
        { version: 'v0.1.1', description: 'Ce patch mineur apporte une traduction en Français ainsi qu\'un meilleur affichage pour le modal de création de compte.'},
        { version: 'v0.1.0', description: 'La version Echowave 0.1.0 est disponible ! \nVous pouvez générer des données sur Youtube et Instagram, communiquer avec votre équipe.' },
        // Ajoutez d'autres notes de mise à jour ici
    ];

    return (
        <div className="modal-overlay" onClick={() => setIsOpen(false)}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-left-border"></div>
                <button className="close-button" onClick={() => setIsOpen(false)}>X</button>

                <div className="modal-header">
                    <div className="modal-image-container">
                        <img src="https://img.freepik.com/free-vector/mathematics-concept-illustration_114360-3972.jpg?t=st=1730809756~exp=1730813356~hmac=ec89b912dc9a4b35eda27ae116a470b04fa4fb4a76b9373d8d6c8c254b4e0969&w=826" alt="Icône des Patchnotes" className="modal-image" />
                    </div>

                    <div className="modal-scrollable-content">
                        <h2>Notes de mise à jour</h2>
                        <p className="modal-description">
                            Restez à jour avec les dernières améliorations et nouvelles fonctionnalités !
                        </p>
                        <div className="patchnotes-list">
                            {patchNotes.map((note, index) => (
                                <div 
                                    key={index} 
                                    className={`patchnote-item ${index === 0 ? 'first-patchnote' : ''}`}
                                >
                                    {index === 0 && <span className="most-recent-label">Le plus récent !</span>}
                                    <h2>{note.version}</h2>
                                    <p>
                                        {note.description.split('\n').map((line, i) => (
                                            <React.Fragment key={i}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PatchnoteModal;
