import React, { useState, useEffect } from "react";
import '../../CircularGraphHours/CircularGraphHours.css';
import { GetUserCompanyData, GetYoutubeData } from "../../../../GatherUserInformationDB/GatherDatabaseUser";
import { GenerateCellStyle } from "../OvertimeAnalyseTable";
import CreateTable from "../TableClass";
import ChartModule from "../../../chartMaker/ChartModule";

const YoutubeDailyDataTable = () => 
{       
    const [rowTableGeneral, setRowTableGenerale] = useState([]);
    const [colTableGeneral, setColTableGeneral] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [graphLabel, setGraphLabel] = useState([
"youtube_likes",
"youtube_dislikes",
"youtube_shares",
"youtube_comments",
    ]);

    const GetDatas = async() => 
    {    
        let allRow = [];
        let finalDataCol = [];
        finalDataCol.push({field: 'date'})
        let graphDataFormatted = [];

        const compData = await GetUserCompanyData();
        finalDataCol.push({field: "youtube_likes", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "youtube_dislikes", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "youtube_shares", cellStyle: GenerateCellStyle()})
        finalDataCol.push({field: "youtube_comments", cellStyle: GenerateCellStyle()})
        Object.entries(compData[0]).forEach(([key, value]) => {
            let finalDataRow = {}; 
            if(!isNaN(new Date(key).getTime()))
            {
                finalDataRow["date"] = key; 
            
                Object.entries(value).forEach(([subKey, subValue]) => {
                    finalDataRow[subKey] = subValue;
                });
                allRow.push(finalDataRow);
                graphDataFormatted.push(finalDataRow);
            }
        });
        allRow.sort((a, b) => 
        {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA - dateB; 
        });
        graphDataFormatted.sort((a, b) => 
        {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA - dateB; 
        });
        setColTableGeneral(finalDataCol);
        setRowTableGenerale(allRow);
        setGraphData(graphDataFormatted);
    };

    useEffect(() => 
    {
        GetDatas();
    }, []);

    return (
        <div>
            <h1>YouTube General Data</h1>
            <CreateTable col={colTableGeneral} row={rowTableGeneral} network="youtube" />
            <ChartModule
                chartTitle="YouTube Interaction Data Overview"
                dataSets={[graphData]}    // Use the fetched graph data
                chartType="line"
                xField="date"
                yFields={graphLabel}      // Label fields for Y-axis
                theme="ag-pastel"
                height={500}
            />
        </div>
    );
};

export default YoutubeDailyDataTable;
