import Cookies from "js-cookie";
import { showChatPopup } from "./showChatPopup";
import { hostLink } from "../DatabaseCommunication/GetDataFromUser";

const GenerateCardMembers = async ({
    currentUserId,
    allTeamUsers,
    teamContentRef,
    selectedUserId,
    userstalkingtoo,
    actualUserName,
    setselectedUserId,
    setCurrentUserId,
    setlatestMessageTimestamp
}) => {
    try {
        const response = await fetch(hostLink + '/GatherUserInformation', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: Cookies.get("authPulseUsername"),
                password: Cookies.get("authPulsePassword")
            })
        });
        const actualUser = await response.json();
        console.log(actualUser['data'][6]);

        const query = (actualUser['data'][6] === "Pulse")
        ? 'SELECT username, company FROM user'
        : 'SELECT username, company FROM user WHERE (company = %s AND username != %s) OR company = "Pulse"';
    

        const queryResponse = await fetch(hostLink + '/SpecialQueryGet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query: query,
                var: (actualUser['data'][6] === "Pulse") ? [] : [actualUser['data'][6], actualUser['data'][1]]
            })
        });

        const user = await queryResponse.json();
        allTeamUsers = user;  // Consider using state setter if needed
        console.log(user);
        
        // Ensure the ref is assigned and accessible before updating
        if (teamContentRef.current) {
            teamContentRef.current.innerHTML = "";
            user.data.forEach(usr => {
                const memberCard = createMemberCard(usr, 0, {
                    selectedUserId,
                    userstalkingtoo,
                    currentUserId,
                    actualUserName,
                    setselectedUserId,
                    setlatestMessageTimestamp
                });
                teamContentRef.current.appendChild(memberCard);
            });
        }
    } catch (error) {
        console.error("Error fetching team members:", error);
    }
};

function createMemberCard(member, index, { selectedUserId, userstalkingtoo, currentUserId, actualUserName, setselectedUserId, setlatestMessageTimestamp }) {
    const card = document.createElement('div');
    card.classList.add('member-card');
    const username = member[0];
    const company = member[1];

    // Add content to the card
    card.innerHTML = `<p>Username: ${username}</p><p>Company: ${company}</p>`;
    
    card.addEventListener('click', async function () {
        const query = 'SELECT id FROM user WHERE username = %s'; // MySQL placeholder
        const values = [username]; // Ensure values is an array
        
        try {
            const response = await fetch(hostLink + '/SpecialQueryGet', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json' // Set the content type to JSON
                },
                body: JSON.stringify({
                    query: query,
                    var: values // Send as an array
                })
            });
            
            const dataUser = await response.json(); // Parse the JSON response
            console.log(dataUser);
            // Check if dataUser.data is defined and has at least one element
            if (dataUser.data && dataUser.data.length > 0) {
                const newSelectedUserId = dataUser.data[0][0];
                console.log("id is : " + newSelectedUserId);
                setselectedUserId(newSelectedUserId); // Update state correctly
                userstalkingtoo = username; // Update the variable
                
                // Pass updated selectedUserId
                showChatPopup(username, {
                    selectedUserId: newSelectedUserId,
                    currentUserId,
                    actualUserName,
                    userstalkingtoo,
                    setlatestMessageTimestamp
                });
            } else {
                console.error('No user found with that username: ' + username);
            }
        } catch (error) {
            console.error("Error fetching user ID:", error);
        }
    });

    return card;
}

export default GenerateCardMembers;
