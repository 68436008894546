import React, { useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

function PdfCreator({ data, generalData, allDatas }) 
{
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => 
    {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const GenerateLastMonthPdf = async () => 
        {
            const doc = new jsPDF("p", "pt", "a4");
            const itemsPerPage = 6;
            const pageHeight = doc.internal.pageSize.height;
            const pageWidth = doc.internal.pageSize.width;
    
            // Grouping data by network
            const groupedData = data.reduce((acc, item) => 
            {
                if (!acc[item.network]) 
                {
                    acc[item.network] = [];
                }
                acc[item.network].push(item);
                return acc;
            }, {});
    
            let currentPage = 1;
            let currentY = 60;
    
            // Cover Page with Summary Statistics
            const addCoverAndSummaryPage = () => 
            {
                doc.setFont("helvetica", "bold");
                doc.setFontSize(26);
                doc.setTextColor(35, 55, 77);
                doc.text(generalData.compName, pageWidth / 2, 80, { align: "center" });
                
                doc.setFontSize(20);
                doc.text("Data Analysis Report", pageWidth / 2, 120, { align: "center" });
                
                doc.setFontSize(12);
                doc.setTextColor(0);
                doc.text(`Date: ${new Date().toLocaleDateString()}`, pageWidth / 2, 140, { align: "center" });
                
                let totalItems = 0;
                let totalChange = 0;
                
                Object.values(groupedData).forEach(entries => 
                {
                    totalItems += entries.length;
                    entries.forEach(item => 
                    {
                        totalChange += item.currentStat - item.previousStat;
                    });
                });
                
                const avgChange = totalChange / totalItems;
                
                doc.setFontSize(14);
                doc.setTextColor(35, 55, 77);
                doc.text("Summary Statistics", pageWidth / 2, 180, { align: "center" });
                
                doc.setFontSize(12);
                doc.setTextColor(0);
                doc.text(`Total items analyzed: ${totalItems}`, pageWidth / 2, 200, { align: "center" });
                doc.text(`Average change across all networks: ${avgChange.toFixed(2)}`, pageWidth / 2, 220, { align: "center" });
                
                const networkList = Object.keys(groupedData).join(", ");
                const splitText = doc.splitTextToSize(`Networks Analyzed: ${networkList}`, pageWidth - 80);
                doc.text(splitText, 40, 250);
                
                doc.addPage();
                currentPage++;
                currentY = 60;
            };
            
            const addTableOfContents = () => 
            {
                doc.setFontSize(16);
                doc.setTextColor(35, 55, 77);
                doc.text("Table of Contents", pageWidth / 2, 40, { align: "center" });
                
                doc.setFontSize(12);
                doc.setTextColor(0);
        
                let tocY = 70;
                Object.keys(groupedData).forEach((network, index) => 
                {
                    if (tocY >= pageHeight - 40) {
                        doc.addPage();
                        tocY = 40;
                    }
                    doc.text(`${index + 1}. ${network} Data`, 40, tocY);
                    tocY += 20;
                });
        
                doc.addPage();
                currentPage++;
                currentY = 60;
            };
            
            const addHeader = () => 
            {
                doc.setFont("helvetica", "bold");
                doc.setFontSize(14);
                doc.setTextColor(35, 55, 77);
                doc.text("Data Report", pageWidth / 2, 40, { align: "center" });
    
                doc.setFontSize(10);
                doc.setTextColor(0);
                doc.text(generalData.compName, 40, 40);
    
                doc.setDrawColor(150);
                doc.line(20, 50, pageWidth - 20, 50);
    
                if (generalData.logo) 
                {
                    doc.addImage(generalData.logo, "JPEG", 15, 30, 40, 40);
                }
            };
    
            const addDataEntryTable = (network, entries) => 
            {
                const tableData = entries.map(item => {
                    const comparison = item.currentStat - item.previousStat;
                    const comparisonText = comparison > 0 ? `+${comparison}` : `${comparison}`;
                    const comparisonColor = comparison > 0 ? [0, 153, 0] : [204, 0, 0];
    
                    return [
                        item.name,
                        item.currentStat,
                        { content: `${item.previousStat} (${comparisonText})`, styles: { textColor: comparisonColor } }
                    ];
                });
    
                doc.autoTable({
                    head: [['Name', 'Current Stat', 'Previous Stat (Comparison)']],
                    body: tableData,
                    startY: currentY,
                    theme: 'striped',
                    styles: { fontSize: 10 },
                    headStyles: { fillColor: [35, 55, 77] },
                    margin: { top: 10, left: 20, right: 20 },
                    didDrawPage: (data) => {
                        addFooter(doc, currentPage);
                        currentPage++;
                        currentY = data.cursor.y + 10;
                    }
                });
            };
    
            const addFooter = (doc, pageNumber) => 
            {
                const pageHeight = doc.internal.pageSize.height;
                const pageWidth = doc.internal.pageSize.width;
    
                doc.setFontSize(10);
                doc.setTextColor(150);
                doc.text(generalData.compName, 20, pageHeight - 20);
                doc.text(`Page ${pageNumber}`, pageWidth - 40, pageHeight - 20);
            };
    
            addCoverAndSummaryPage();
            addTableOfContents();
            addHeader();
    
            for (const network in groupedData) 
            {
                doc.setFontSize(14);
                doc.setTextColor(35, 55, 77);
                doc.setDrawColor(35, 55, 77);
                doc.rect(20, currentY, pageWidth - 40, 20);
                doc.text(`${network} Data`, 30, currentY + 15);
                currentY += 30;
                
                addDataEntryTable(network, groupedData[network]);
    
                if (currentY > pageHeight - 60) 
                {
                    doc.addPage();
                    currentPage++;
                    currentY = 60;
                    addHeader();
                }
            }
    
            addFooter(doc, currentPage);
    
            const pdfBlob = doc.output("blob");
            const url = URL.createObjectURL(pdfBlob);
            window.open(url);
        };
        const GenerateYearlyPdf = async () => {
            const doc = new jsPDF("p", "pt", "a4");
            const pageHeight = doc.internal.pageSize.height;
            const pageWidth = doc.internal.pageSize.width;
        
            const isValidDateKey = (key) => {
                // Vérifie si la clé suit le format YYYY-MM
                return /^\d{4}-\d{2}$/.test(key);
            };
        
            // Filtrer les clés valides et les trier
            let sortedMonths = Object.keys(allDatas)
                .filter(isValidDateKey)
                .sort();
        
            console.log(allDatas);
            console.log(sortedMonths);
        
            if (sortedMonths.length === 0) {
                console.error("No valid date keys found in the data.");
                return;
            }
        
            const lastMonth = sortedMonths[sortedMonths.length - 1];
            const firstMonth = sortedMonths.find((month) => month.startsWith(lastMonth.split('-')[0]));
            const firstMonthIndex = sortedMonths.indexOf(firstMonth);
            sortedMonths = sortedMonths.slice(firstMonthIndex);
        
            let currentPage = 1;
            let currentY = 60;
        
            const calculateStatistics = (data) => {
                const sortedData = [...data].sort((a, b) => a - b);
                const sum = data.reduce((acc, val) => acc + val, 0);
                const avg = sum / data.length;
                const min = Math.min(...data);
                const max = Math.max(...data);
                const median = data.length % 2 === 0
                    ? (sortedData[data.length / 2 - 1] + sortedData[data.length / 2]) / 2
                    : sortedData[Math.floor(data.length / 2)];
        
                // Calcul de l'écart-type
                const variance = data.reduce((acc, val) => acc + Math.pow(val - avg, 2), 0) / data.length;
                const stdDev = Math.sqrt(variance);
        
                return { avg, min, max, median, stdDev };
            };
        
            const calculateAverageEvolution = (values) => {
                let totalEvolution = 0;
                let count = 0;
                for (let i = 1; i < values.length; i++) {
                    const prevValue = values[i - 1] || 1; // éviter la division par 0
                    const currentValue = values[i] || 1;
                    totalEvolution += ((currentValue - prevValue) / prevValue) * 100;
                    count++;
                }
                return count > 0 ? totalEvolution / count : 0;
            };
        
            const addHeader = () => {
                doc.setFont("helvetica", "bold");
                doc.setFontSize(14);
                doc.setTextColor(35, 55, 77);
                doc.text("Bilan annuel des statistiques", pageWidth / 2, 40, { align: "center" });
        
                doc.setDrawColor(150);
                doc.line(20, 50, pageWidth - 20, 50);
            };
        
            const addFooter = () => {
                doc.setFontSize(10);
                doc.setTextColor(150);
                doc.text(`Page ${currentPage}`, pageWidth - 40, pageHeight - 20);
            };
        
            const addStatisticsTable = (dataKey, stats, monthlyValues, months) => {
                doc.autoTable({
                    head: [["Statistic", "Value"]],
                    body: [
                        ["Moyenne", stats.avg.toFixed(2)],
                        ["Minimum", stats.min],
                        ["Maximum", stats.max],
                        ["Médiane", stats.median],
                        ["Evolution moyenne (%)", stats.avgEvolution.toFixed(2) + "%"],
                        ["Evolution totale (%)", stats.totalEvolution ? stats.totalEvolution.toFixed(2) + "%" : "N/A"],
                        ["Ecart-type", stats.stdDev.toFixed(2)],
                    ],
                    startY: currentY,
                    theme: "grid",
                    headStyles: { fillColor: [35, 55, 77] },
                    margin: { top: 10, left: 20, right: 20 },
                });
        
                currentY = doc.autoTable.previous.finalY + 20;
        
                if (currentY > pageHeight - 60) {
                    doc.addPage();
                    currentPage++;
                    currentY = 60;
                }
        
                // Ajout des évolutions mensuelles dans un tableau
                const evolutionData = monthlyValues.map((value, index) => {
                    if (index > 0) {
                        const prevValue = monthlyValues[index - 1] || 1;
                        const currentValue = value || 1;
                        const evolution = calculateMonthlyEvolution(currentValue, prevValue);
                        
                        // Calcul de l'évolution comparée à l'année précédente
                        const currentMonth = months[index];
                        const previousYearMonth = `${parseInt(currentMonth.split('-')[0]) - 1}-${currentMonth.split('-')[1]}`;
                        const previousYearValue = allDatas[previousYearMonth]?.[dataKey] || 1;
                        const previousYearEvolution = calculateMonthlyEvolution(currentValue, previousYearValue);
        
                        return {
                            month: `${months[index - 1]} à ${months[index]}`,
                            evolution: evolution.toFixed(2) + "%",
                            previousYearEvolution: previousYearEvolution.toFixed(2) + "%",
                            color: evolution > 0 ? [34, 193, 34] : (evolution < 0 ? [255, 0, 0] : [0, 0, 0]),
                        };
                    }
                    return null;
                }).filter(Boolean); // Filter out the nulls
        
                // Affichage des évolutions mensuelles
                doc.autoTable({
                    head: [["Période", "Evolution", "Comparé à l'année d'avant"]],
                    body: evolutionData.map(item => [item.month, item.evolution, item.previousYearEvolution]),
                    startY: currentY,
                    theme: "grid",
                    headStyles: { fillColor: [35, 55, 77] },
                    styles: {
                        cellWidth: "auto",
                        fontSize: 10,
                    },
                });
        
                currentY = doc.autoTable.previous.finalY + 20;
                if (currentY > pageHeight - 60) {
                    doc.addPage();
                    currentPage++;
                    currentY = 60;
                }
            };
        
            const calculateMonthlyEvolution = (current, previous) => ((current - previous) / previous) * 100;
        
            addHeader();
        
            for (const dataKey of Object.keys(allDatas[firstMonth])) {
                const monthlyValues = sortedMonths.map((month) => allDatas[month]?.[dataKey] || 0);
                const stats = calculateStatistics(monthlyValues);
        
                const totalEvolution = calculateMonthlyEvolution(monthlyValues[monthlyValues.length - 1], monthlyValues[0]);
                stats.totalEvolution = totalEvolution;
        
                const avgEvolution = calculateAverageEvolution(monthlyValues);
                stats.avgEvolution = avgEvolution;
        
                doc.setFont("helvetica", "bold");
                doc.setFontSize(12);
                doc.setTextColor(35, 55, 77);
                doc.text(`${dataKey}`, 30, currentY);
                currentY += 20;
        
                addStatisticsTable(dataKey, stats, monthlyValues, sortedMonths);
        
                currentY = 60;
                doc.addPage();
            }
        
            addFooter();
        
            const pdfBlob = doc.output("blob");
            const url = URL.createObjectURL(pdfBlob);
            window.open(url);
        };
        
        
        
            
    return (
        <>
            <button className="button" onClick={toggleSidebar}>
                PDF
            </button>

            <div style={{ 
                ...styles.sidebar, 
                transform: isSidebarOpen ? "translateX(0)" : "translateX(100%)" 
            }}>
                <button style={styles.closeButton} onClick={toggleSidebar}>×</button>
                <h1 style={styles.title}>Générateur de PDF</h1>
                <h2>Crée un bilan sur les dernières données (dernier mois).</h2>
                <button style={styles.generateButton} onClick={GenerateLastMonthPdf}>
                    Générer le PDF
                </button>
                <h2>Crée un bilan sur l'année en cours.</h2>
                <button style={styles.generateButton} onClick={GenerateYearlyPdf }>
                    Générer le PDF
                </button>
            </div>
        </>
    );
}

const styles = {
    sidebar: {
        position: 'fixed',
        top: '0',
        right: '0',
        height: '100%',
        width: '300px',
        backgroundColor: '#f9f9f9',
        borderLeft: '1px solid #ddd',
        padding: '20px',
        zIndex: '1000',
        boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease',
    },
    closeButton: {
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '24px',
        cursor: 'pointer',
        color: '#aaa',
        position: 'absolute',
        top: '15px',
        right: '15px',
    },
    title: {
        fontSize: '20px',
        color: '#23374d',
        fontWeight: '600',
        marginTop: '40px',
        textAlign: 'center',
    },
    generateButton: {
        backgroundColor: '#23374d',
        color: 'white',
        padding: '12px 25px',
        fontSize: '16px',
        fontWeight: 'bold',
        cursor: 'pointer',
        borderRadius: '6px',
        width: '100%',
        textAlign: 'center',
        border: 'none',
        marginTop: '20px',
    }
};

export default PdfCreator;
