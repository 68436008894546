import React, { useState, useEffect } from 'react';
import ProjectCard from './ProjectCard';
import ProjectModal from './ProjectModal';
import './CompanyFeed.css';
import EventList from './EventList.js';
import SideMenu from './SideBar/sideBar.js';
import Team from '../Sidebar/Team.js';
import Notifications from '../Sidebar/Notification.js';
import TaskViewer from './TaskViewer.js';

const CompanyFeed = () => {
  const [projects, setProjects] = useState([]);
  const [view, setView] = useState("events");
  useEffect(() => {
    // Charger les données des projets (données statiques ou requêtes API)
    let projectData = [
        { 
            id: 1, 
            title: 'Project for Company1', 
            content: 'Website creation', 
            dueDate: new Date('2024-12-01'), 
            events: [
              { date: new Date("2024-09-15"), title: "Created backend", desc: "We've created the backend", idUsers: [0, 1], brdColor: "rgb(255, 99, 71)", inf: "none" }, 
              { date: new Date("2024-09-20"), title: "Design mockups", desc: "Initial mockups for the website design were created", idUsers: [0, 1], brdColor: "rgb(135, 206, 235)", inf: "warn" }, 
              { date: new Date("2024-09-25"), title: "Frontend development", desc: "Started developing the frontend using React.js", idUsers: [0], brdColor: "rgb(60, 179, 113)", inf: "none" }, 
              { date: new Date("2024-10-05"), title: "Client review", desc: "Presented the initial design to the client for feedback", idUsers: [1], brdColor: "rgb(255, 215, 0)", inf: "fail" }, 
              { date: new Date("2024-10-15"), title: "Integrated API", desc: "Connected the frontend with the backend API", idUsers: [0, 1], brdColor: "rgb(100, 149, 237)", inf: "none" }, 
              { date: new Date("2024-11-01"), title: "Final testing", desc: "Conducted final testing and debugging of the website", idUsers: [0], brdColor: "rgb(255, 165, 0)", inf: "warn" }, 
              { date: new Date("2024-11-15"), title: "Client approval", desc: "Received final approval from the client", idUsers: [1], brdColor: "rgb(34, 139, 34)", inf: "none" }, 
          ], 
            tasks: [
                {title: "Publish website", desc: "Use a website hosting service to publish the website", idUsers: [0], isDone: true, addInfo: [["2024-05-11", "website operational"],["2024-07-11", "website updated"],]},
                {title: "Create content", desc: "Prepare and add content for the website", idUsers: [0, 1], isDone: false, addInfo: []},
                {title: "SEO optimization", desc: "Implement SEO best practices for better search visibility", idUsers: [0], isDone: false, addInfo: []},
                {title: "Set up analytics", desc: "Integrate Google Analytics for tracking user engagement", idUsers: [0], isDone: false, addInfo: []},
                {title: "Create user documentation", desc: "Write user documentation for website management", idUsers: [0], isDone: false, addInfo: []},
                {title: "Train client", desc: "Conduct a training session for the client on how to use the website", idUsers: [0, 1], isDone: false, addInfo: []},
            ]
        }
    ];
    setProjects(projectData);

    
  }, []);

  const [selectedProject, setSelectedProject] = useState(null);

  // Méthode pour ouvrir un projet
  const openProject = (project) => {
    setSelectedProject(project);
  };

  return (
    <div id="background">
      <SideMenu/>


      <div className="access-bar-content">
                <Notifications />
                <Team />
            </div>

      <div className="pageContainerr">
        <div className="container generator" id="projectFeed">
          <hr />
          <div className="horizontalBox" id="projectFeedDetails">
            {projects.map((project) => (
              <ProjectCard key={project.id} project={project} openProject={openProject} />
            ))}
          </div>
        </div>
        {selectedProject && (
          <div  className="container generator">     
          <div className="buttonsViewer">
                  <button className='button' onClick={() => setView("events")}>Events</button>
                  <button className='button' onClick={() => setView("tasks")}>Tasks</button>
                  <button className='button' onClick={() => setView("discussion")}>Discussion</button>
              </div>
          </div>
        )}

        {selectedProject && view == "events" && (
          <div  >
            <EventList initialEvents={selectedProject.events} />
          </div>
        )}
        {selectedProject && view == "tasks" && (
          <div >
            <TaskViewer tasks={selectedProject.tasks} />
          </div>
        )}
        {selectedProject && view == "discussion" && (

          <div className="container generator" id="discussionFeed">
              <h1>Discussion</h1>
              <hr />
            <div className="card" id="discussionMsgs"></div>
          </div>

        )}

        {/* Bouton pour ouvrir le modal */}
        <ProjectModal projectList= {projects} setProjects= {setProjects}/>
      </div>
    </div>
  );
};

export default CompanyFeed;
