import React, { useState } from "react";
import "./NetworkChooser.css";

const NetworkChooser = ({ ChangeStatsNetworkType }) => 
{
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [hoverTransform, setHoverTransform] = useState({});
    const [hoveredNetwork, setHoveredNetwork] = useState(null);

    const networks = [
        {
            name: "instagram",
            logo: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png",
            type: "INSTAGRAM",
            color: "#E1306C", // Instagram pink
        },
        {
            name: "youtube",
            logo: "https://upload.wikimedia.org/wikipedia/commons/4/42/YouTube_icon_%282013-2017%29.png",
            type: "YOUTUBE",
            color: "#FF0000", // YouTube red
        },
        {
            name: "tiktok",
            logo: "https://static.wikia.nocookie.net/tiktok/images/e/eb/TikTok_Logo.png",
            type: "TIKTOK",
            color: "#010101", // TikTok black
        },
    ];

    const handleMouseMove = (e, index) => 
    {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width - 0.5) * 100;
        const y = ((e.clientY - rect.top) / rect.height - 0.5) * -100;

        setHoverTransform((prev) => ({
            ...prev,
            [index]: `rotateY(${x}deg) rotateX(${y}deg) scale(1.2)`,
        }));
        setHoveredNetwork(networks[index]);
    };

    const handleMouseLeave = (index) => 
    {
        setHoverTransform((prev) => ({
            ...prev,
            [index]: "rotateY(0deg) rotateX(0deg)",
        }));
        setHoveredNetwork(null);
    };

    return (
        <div className={`menuOpener ${isMenuOpen ? "menuOpen" : ""}`}>
            {isMenuOpen && (
                <div className="overlay" onClick={() => setMenuOpen(false)}>
                    <div className="title">Quel réseau analysons nous ?</div>

                    <div className="circleMenu">
                        {networks.map((network, index) => {
                            const angle = (index / networks.length) * 2 * Math.PI;
                            const x = Math.cos(angle) * 100;
                            const y = Math.sin(angle) * 100;

                            return (
                                <div
                                    key={network.type}
                                    className="menuItem"
                                    style={{
                                        transform: `translate(${x}px, ${y}px)`,
                                    }}
                                    onMouseMove={(e) => handleMouseMove(e, index)}
                                    onMouseLeave={() => handleMouseLeave(index)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        ChangeStatsNetworkType(network.name);
                                        setMenuOpen(false);
                                    }}
                                >
                                    <img
                                        src={network.logo}
                                        alt={network.name}
                                        style={{
                                            transform: hoverTransform[index] || "rotateY(0deg) rotateX(0deg)",
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    <div className="ftr">
                        {hoveredNetwork ? (
                            <span
                                style={{
                                    color: hoveredNetwork.color,
                                }}
                            >
                                analysons {hoveredNetwork.name}
                            </span>
                        ) : (
                            "analysons..."
                        )}
                    </div>
                </div>
            )}
            <div className="button" onClick={() => setMenuOpen(!isMenuOpen)}>
                Réseaux sociaux
            </div>
        </div>
    );
};

export default NetworkChooser;
