import React from "react";
import "./PrivacyPolicy.css";
import Footer from "../../EchowaveLandingPage/components/Footer";
import Header from "../../EchowaveLandingPage/components/Header";

const PrivacyPolicyPage = () =>
    {
        return (
            <section>
                <Header/>
                <div className="privacy-policy-container">
                    <h1>Politique de Confidentialité</h1>
                    <p><strong>Date d'entrée en vigueur : 27.09.2024</strong></p>
                    
                    <h2>1. Informations que nous collectons</h2>
                    <p>Nous pouvons collecter et traiter les types d'informations suivants :</p>
                    <ul>
                        <li><strong>Informations personnelles :</strong> Nom, adresse e-mail, numéro de téléphone, adresse de facturation et détails de paiement.</li>
                        <li><strong>Données d'utilisation :</strong> Informations sur la manière dont vous utilisez notre site Web ou notre application.</li>
                        <li><strong>Cookies et données de suivi :</strong> Nous utilisons des cookies et des technologies de suivi similaires.</li>
                    </ul>
    
                    <h2>2. Collecte de données via les réseaux sociaux</h2>
                    <p>Lorsque vous utilisez notre analyseur de réseaux sociaux :</p>
                    <ul>
                        <li>Les données collectées lors de l'analyse sont sauvegardées sur nos serveurs.</li>
                        <li>Toutes les données sont uniquement sauvegardées et non utilisées à des fins commerciales.</li>
                        <li>Notre base de données est sécurisée et hébergée sur un service fiable.</li>
                    </ul>
    
                    <h2>3. Système de messagerie directe</h2>
                    <p>Tous les messages envoyés via notre système de messagerie directe sont stockés de manière sécurisée dans notre base de données. Nous voulons vous assurer que :</p>
                    <ul>
                        <li>Aucun message n'est traité, vendu ou utilisé à d'autres fins.</li>
                        <li>Notre base de données est sécurisée et hébergée sur un service fiable, garantissant la confidentialité de vos messages.</li>
                    </ul>
    
                    <h2>4. Comment nous utilisons vos informations</h2>
                    <p>Nous utilisons les informations collectées pour les objectifs suivants :</p>
                    <ul>
                        <li>Fournir et maintenir nos services.</li>
                        <li>Traiter les paiements.</li>
                        <li>Améliorer nos services.</li>
                        <li>Communiquer avec vous.</li>
                        <li>Obligations légales.</li>
                    </ul>
    
                    <h2>5. Partage de vos informations</h2>
                    <p>Nous ne vendons, échangeons ni ne transférons autrement vos informations personnelles à des tiers, sauf dans les cas suivants :</p>
                    <ul>
                        <li>Fournisseurs de services.</li>
                        <li>Exigences légales.</li>
                    </ul>
    
                    <h2>6. Sécurité des données</h2>
                    <p>Nous mettons en œuvre des mesures techniques et organisationnelles appropriées pour protéger vos données personnelles.</p>
    
                    <h2>7. Vos droits de protection des données</h2>
                    <p>Vous disposez des droits suivants concernant vos informations personnelles :</p>
                    <ul>
                        <li>Accès, correction, suppression, restriction et portabilité des données.</li>
                    </ul>
    
                    <h2>8. Conservation des données</h2>
                    <p>Nous conservons vos informations personnelles uniquement pendant la durée nécessaire pour remplir les objectifs pour lesquels elles ont été collectées.</p>
    
                    <h2>9. Cookies</h2>
                    <p>Notre site Web utilise des cookies pour améliorer l'expérience utilisateur.</p>
    
                    <h2>10. Modifications de cette politique de confidentialité</h2>
                    <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment.</p>
    
                    <h2>11. Contactez-nous</h2>
                    <p>Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à l'adresse suivante :</p>
                    <p><strong>Pulse Software</strong><br />
                    Email : dotsoft.pro@gmail.com<br /></p>
                </div>
                <Footer/>
            </section>
        );
    }
    

export default PrivacyPolicyPage;
