import React, { useState, useEffect } from 'react';
import "./ActualityNote.css";
import { hostLink } from '../DatabaseCommunication/GetDataFromUser';

function Notifications() 
{
    const [isActualityVisible, setActualityVisible] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const toggleContainer = () => 
    {
        setActualityVisible(!isActualityVisible);
    };

    useEffect(() => 
    {
        if (isActualityVisible) 
        {
            const GetDatasFromTable = async () => 
            {
                try 
                {
                    const result = await fetch(hostLink + '/GetActAndPn', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    });
                    const resultParsed = await result.json();
                    setNotifications(resultParsed['data']);
                } 
                catch (error) 
                {
                    console.error("Error fetching data: ", error);
                }
            };
            GetDatasFromTable();
        }
    }, [isActualityVisible]);

    return (
        <div>
            <button className="button" onClick={toggleContainer}>Notifications</button>
            {isActualityVisible && (
                <div className="teamContainer" id="notifAndPatch">
                    <button className="close-btn" onClick={toggleContainer}>×</button>
                    <div className="mainTitle">Notifications et patchnote</div>
                    <hr />
                    <div className="container" id="containerNotifPn">
                        {notifications.length > 0 ? (
                            notifications.map((notif, index) => {
                                const date = new Date(notif[1]);
                                const title = notif[2];
                                const content = notif[3].replace(/\\n/g, '<br />');
                                
                                return (
                                    <div key={index} className="card">
                                        <h3>{title}</h3>
                                        {/* Use dangerouslySetInnerHTML to render content with <br /> */}
                                        <p dangerouslySetInnerHTML={{ __html: content }}></p>
                                        <span>{date.toDateString()}</span>
                                    </div>
                                );
                            })
                        ) : (
                            <p>Pas de notifications</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Notifications;
