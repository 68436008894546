import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import jsPDF from 'jspdf';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ChartToPDF = () => {
    const chartRef = useRef(null);

    // Chart data and options
    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [
            {
                label: 'Sales',
                data: [30, 20, 50, 40, 70, 60],
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Monthly Sales Data',
            },
        },
    };

    // Function to generate PDF
    const generatePDF = () => {
        const chart = chartRef.current;
        if (chart) {
            const chartImage = chart.toBase64Image();
            const pdf = new jsPDF();
            pdf.text('Sales Data Chart', 10, 10);
            pdf.addImage(chartImage, 'PNG', 15, 40, 180, 100); // Position and scale chart in the PDF
            pdf.save('chart.pdf'); // Save the PDF
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <h2>Chart to PDF Example</h2>
            <Bar ref={chartRef} data={data} options={options} />
            <button onClick={generatePDF} style={{ marginTop: '20px', padding: '10px 20px' }}>
                Generate PDF
            </button>
        </div>
    );
};

export default ChartToPDF;
